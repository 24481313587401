import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const PageHeaderAbout = (props) => {
	let HeaderTitle = props?.headertitle;
	let publicUrl = process.env.PUBLIC_URL+'/'
	let Subheader = props?.subheader ? props?.subheader : HeaderTitle
	let CustomClass = props?.customclass ? props?.customclass : ''
	let Img = props?.Img ? props?.Img :'14.jpg'

	return (

	<div className={"ltn__breadcrumb-area ltn__breadcrumb-area-about bg-image-bottom overflow-hidden text-left bg-overlay-black-60 bg-image "+CustomClass} data-bs-bg={publicUrl+"assets/img/llabdhi_img/team-image3.jpg"} style={{backgroundImage: `url({${publicUrl}+"/assets/img/llabdhi_img/team-image3.jpg"})`}}>
		<div className="container-fluid px-0">
			<div className="row">
			<div className="col-lg-12">
				<div className="hero-section-title-tag">
					<h1>
						<span>Turning</span>&nbsp;<span>Your Dreams</span>&nbsp;<span>into</span>&nbsp;<span>Our Commitment.</span>
					</h1>
				</div>
				{/* <div className="ltn__breadcrumb-inner text-center">
					<h1 className="page-title">{ HeaderTitle }</h1>
					<p>Building Tomorrow's Foundations Today with Llabdhi Manufacturing</p>
				</div> */}
			</div>
			</div>
		</div>
	</div>
	)
}


export default PageHeaderAbout