import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetailsSection from './shop-components/shop-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import PageHeaderAnimated from './global-components/page-header-animated';
import { Helmet } from 'react-helmet-async';

const ProductDetails = () => {
    const { slug } = useParams();

    var productId = 0;
    var productMetaTitle = "";
    var productMetaDesc = "";

        if(slug == "2bba-mill-finish") { 
            productId = 5;
            productMetaTitle = "High-Quality Stainless Steel Sheets| 2B/BA Mill Finish |Llabdhi Industries";
            productMetaDesc = "Explore our range of stainless steel sheets, including 2B and mill finishes. Discover top-quality steel products, including gold sheets, at LLabdhi."
        } else if(slug == "hairline-finish-stainless-steel-sheets") {
            productId = 6
            productMetaTitle = "Hairline Finish Stainless Steel Sheets | Llabdhi Industries";
            productMetaDesc = "Discover the difference between hairline and satin finish stainless steel. Find polished stainless steel coil suppliers for quality hairline finish sheets."
        } else if(slug == "super-mirror-sheet") {
            productId = 7
            productMetaTitle = "#8 Super Mirror Finish Stainless Steel Sheet| Llabdhi Industries";
            productMetaDesc = "Explore our premium mirror finish stainless steel products at LLABDHI. From cabinets to sheets, find the perfect solution for your needs."
        } else if(slug == "stainless-steel-strips") {
            productId = 8
            productMetaTitle = "Stainless Steel Strip Manufacturers and suppliers | Llabdhi Industries";
            productMetaDesc = "Explore our premium stainless steel strip coils and flat trim strips for versatile applications. Quality thin and decorative options available"
        } else {
            productId = 9
            productMetaTitle = "Brushed Stainless Steel | Premium #4 Finish Sheets | Llabdhi Industries";
            productMetaDesc = "Explore high-quality brushed stainless steel products, including sheets with a #4 finish, at Llabdhi."
        }

    return <div>
        <Helmet>
            <title>{productMetaTitle}</title>
            <meta name="description" content={productMetaDesc} />
        </Helmet>
        <Navbar />
        <PageHeaderAnimated />
        <ProductDetailsSection id={productId} />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default ProductDetails

