import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import AboutV1 from './section-components/about-v1';
import VisionSection from './section-components/vision-section';
import MissionSection from './section-components/mission-section';
import CoreValues from './section-components/core-values';
import CoreFeatures from './section-components/core-features';
import ProSlider from './section-components/product-slider-v1';
import TestimonialCustom from './section-components/testimonial-custom';
import BlogSliderCustom from './blog-components/blog-slider-custom';
import Sponsor from './section-components/sponsor-v1';
import ContentPage from './section-components/content';

const TermsConditions = () => {

    return <div>
        <Navbar />
        <PageHeader headertitle="Terms & Conditions / Privacy Policy" />
        <ContentPage />
        <CallToActionV1 />
        <Footer />
    </div>
}



export default TermsConditions

