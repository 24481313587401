import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Select from "react-select";
import config from '../../config/config';
import { toast } from 'react-toastify';

const QualityTestSection = () => {

	let publicUrl = process.env.PUBLIC_URL + '/'
	
	return <div className="ltn__quality-test-section pt-50 pb-30">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-lg-6 fade-in-up">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-30">
							<h6 className="section-subtitle section-subtitle-2 secondary-color">Quality Test</h6>
							<h5>We live in a rapidly changing world, and witness profound changes in society, consciousness, foundations, business. </h5>
							<p>But invariable were and still are the basic principles on which the activity of the company has been built - honesty in relations with customers as partners, an extensive range of products, consistently high quality of our cement, accessible prices, social responsibility, the desire for stability.</p>
							{/* <div className="btn-wrapper mt-30">
								<Link className="btn theme-btn-1 btn-effect-1 text-uppercase" to={"/quality-test"}>Quality Test Equipments</Link>
							</div> */}
						</div>
					</div>
				</div>
				<div className="col-lg-6 align-self-center">
					<img src={publicUrl + "assets/img/llabdhi_img/Quality-Control-Checklist.jpg"}></img>
				</div>
			</div>
		</div>
	</div>
}

export default QualityTestSection