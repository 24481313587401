import React from 'react';
// import ColorSwatches from '../section-components/color-swatch';

const InquiryModal  = () => {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__modal-area ltn__quick-view-modal-area">
		<div className="modal fade" id="inquiry-modal" tabIndex={-1}>
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
							{/* <i class="fas fa-times"></i> */}
						</button>
					</div>
					<div className="modal-body">
						<div className="ltn__quick-view-modal-inner">
							<div className="modal-product-item">
								<div className="row">
									{/* <div className="col-lg-6 col-12">
											<div className="modal-product-img">
												<img src={publicUrl + "assets/img/product/4.png"} alt="#" />
											</div>
										</div> */}
									<form action="#">
										<h6>Personal Information</h6>
										<div className="row">
											<div className="col-md-6">
												<div className="input-item input-item-name ltn__custom-icon">
													<input type="text" name="ltn__name" placeholder="First Name" />
												</div>
											</div>
											<div className="col-md-6">
												<div className="input-item input-item-name ltn__custom-icon">
													<input type="text" name="ltn__lastname" placeholder="Last Name" />
												</div>
											</div>
											<div className="col-md-6">
												<div className="input-item input-item-email ltn__custom-icon">
													<input type="email" name="ltn__email" placeholder="Email Address" />
												</div>
											</div>
											<div className="col-md-6">
												<div className="input-item input-item-phone ltn__custom-icon">
													<input type="text" name="ltn__phone" placeholder="Phone Number" />
												</div>
											</div>
											<div className="col-md-6">
												<div className="input-item input-item-website ltn__custom-icon">
													<input type="text" name="ltn__company" placeholder="Company Name (optional)" />
												</div>
											</div>
											<div className="col-md-6">
												<div className="input-item input-item-website ltn__custom-icon">
													<input type="text" name="ltn__phone" placeholder="Company Address (optional)" />
												</div>
											</div>
										</div>
										<div className="row">
											{/* <div className="col-lg-4 col-md-6">
						<h6>Country</h6>
						<div className="input-item">
							<select className="nice-select">
							<option>Select Country</option>
							<option>Australia</option>
							<option>Canada</option>
							<option>China</option>
							<option>Morocco</option>
							<option>Saudi Arabia</option>
							<option>United Kingdom (UK)</option>
							<option>United States (US)</option>
							</select>
						</div>
						</div> */}
											<div className="col-lg-12 col-md-12">
												<h6>Address</h6>
												<div className="row">
													<div className="col-md-6">
														<div className="input-item">
															<input type="text" placeholder="House Number And Street Name" />
														</div>
													</div>
													<div className="col-md-6">
														<div className="input-item">
															<input type="text" placeholder="Apartment, Suite, Unit etc. (optional)" />
														</div>
													</div>
												</div>
											</div>
											{/* <div className="col-lg-4 col-md-6">
												<h6>Town / City</h6>
												<div className="input-item">
													<input type="text" placeholder="City" />
												</div>
											</div>
											<div className="col-lg-4 col-md-6">
												<h6>State </h6>
												<div className="input-item">
													<input type="text" placeholder="State" />
												</div>
											</div>
											<div className="col-lg-4 col-md-6">
												<h6>Zip</h6>
												<div className="input-item">
													<input type="text" placeholder="Zip" />
												</div>
											</div> */}
										</div>
										<div className="d-flex justify-content-center">
											<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Enquire Now</button>
										</div>

										{/* <p><label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Create an account?</label></p>
										<h6>Order Notes (optional)</h6>
										<div className="input-item input-item-textarea ltn__custom-icon">
											<textarea name="ltn__message" placeholder="Notes about your order, e.g. special notes for delivery." defaultValue={""} />
										</div> */}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	}


export default InquiryModal