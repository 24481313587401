import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const AboutV1 = ({customClass}) => {

	let publicUrl = process.env.PUBLIC_URL + '/'

	return <div className={"ltn__about-us-area pb-60 " + customClass}>
		<div className="container">
			<div className="row">
				<div className="col-lg-5 align-self-center">
					<div className="about-us-img-wrap about-img-left">
						<div className="exp-container">
							<h4 className="exp-num">
								20+
							</h4>
							<h6 className="exp-text">
								Years Of Experience
							</h6>
						</div>
						<img src={publicUrl + "assets/img/llabdhi_img/products_bg_image.png"} alt="About Us Image" />
						{/* <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
							<div className="ltn__video-img ltn__animation-pulse1">
								<img src={publicUrl + "assets/img/llabdhi_img/intro_video.jpg"} alt="video popup bg image" />
								<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
									<i className="fa fa-play" />
								</a>
							</div>
						</div> */}
					</div>
				</div>
				<div className="col-lg-7 align-self-center fade-in-up">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
							<h6 className="section-subtitle section-subtitle-2 secondary-color">About Us</h6>
							<h1 className="section-title">We believe that the entryway is <span>the centerpiece of every home.</span></h1>
							<p>
								LLABDHI Group offers convenient, customized just-in-time services to the customer's doorstep. The company specializes in the business of processing and distribution of stainless steel to serve its valued customers with exact Slit, Cut-to-Size, Polished  Stainless Steel Sheets, Coils and Blanks conforming to highest standards of processing tolerances.
							</p>
							<p>
								Group invests in the best technology and staff training, customer service is our aim providing an efficient, flexible and dynamic services which will help us to be one of the leading service centres for stainless steel.
							</p>
						</div>
						<ul className="ltn__list-item-half clearfix">
							<li>
								{/* <i className="flaticon-home-2" /> */}
								<div className="about-icon-img">
									<img src={publicUrl + "assets/img/llabdhi_img/machine.svg"} />
								</div>
								Customized Stainless Steel
							</li>
							<li>
								<div className="about-icon-img">
									<img src={publicUrl + "assets/img/llabdhi_img/inv_check.svg"} />
								</div>
								Inventory Management
							</li>
							<li>
								<div className="about-icon-img">
									<img src={publicUrl + "assets/img/llabdhi_img/warehouse.svg"} />
								</div>
								Warehousing
							</li>
							<li>
								<div className="about-icon-img">
									<img src={publicUrl + "assets/img/llabdhi_img/clock.svg"} />
								</div>
								Just In Time Service
							</li>
						</ul>
						{/* <div className="ltn__callout bg-overlay-theme-05  mt-30">
							<p>"Enimad minim veniam quis nostrud exercitation <br />
								llamco laboris. Lorem ipsum dolor sit amet" </p>
						</div> */}
						{/* <div className="btn-wrapper animated go-top">
							<Link to="/about" className="theme-btn-1 btn btn-effect-1">Know More</Link>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default AboutV1