import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const MissionSection = () => {

	let publicUrl = process.env.PUBLIC_URL+'/'

    return (
		<div className="latest-mission-section pt-50 pb-60 overflow-hidden">
			<div className="container">
				<div className="row">
					
				</div>
			</div>
		
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="portfolio-section-wrap portfolio-section-left">
							<img src={publicUrl + "assets/img/llabdhi_img/team-image42.jpg"} alt="About Us Image" />
							{/* <div className="portfolio-section-info portfolio-section-info-2 portfolio-section-info-3">
								<div className="ltn__animation-pulse1">
									<img src={publicUrl + "assets/img/llabdhi_img/design_pattern_1.svg"} alt="video popup bg image" />
								</div>
							</div>
							<div className="portfolio-section-info portfolio-section-info-2 portfolio-section-info-4">
								<div className="ltn__animation-pulse1">
									<img src={publicUrl + "assets/img/llabdhi_img/design_pattern_2.svg"} alt="video popup bg image" />
								</div>
							</div> */}
						</div>
					</div>
					<div className="col-lg-6 px-5 fade-in-up d-xs-flex flex-xs-column">
						<div className="section-title-area ltn__section-title-2--- mb-20">
							<div>
								<h6 className="section-subtitle section-subtitle-2 secondary-color">What We Belive</h6>
								<h1 className="section-title">Our <span>Mission</span></h1>
							</div>
							<div>
								<p>
								At Llabdhi Group, our mission is to lead the steel processing industry with an unwavering commitment to precision and customer satisfaction. Specializing in the Processing & Distribution of stainless steel, we take pride in delivering customized solutions that cater to the unique needs of our valued customers. With a dedication to excellence, we strive to be your trusted partner, ensuring that every product from Llabdhi Group meets and exceeds your expectations.
								</p>
							</div>
						</div>
						{/* <div className="portfolio-left-container">
							<img src={publicUrl + "assets/img/llabdhi_img/mission_extra_image.jpg"} alt="About Us Image" />
						</div> */}
					</div>
				</div>
			</div>
		</div>
	)}

export default MissionSection;