import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';
import InquiryModal from './inquiry-modal';
import Preloader from './preloader';
import AnimatedCursor from './animated-cursor';
import config from '../../config/config';
import { addDetail } from "../../actions/contactsAction";
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Toaster from './toaster';

const Footer_v1 = ({addDetail}) => {

	let publicUrl = process.env.PUBLIC_URL+'/';
	let imgattr = "Footer logo";

	const [contactDetails, setContactDetails] = useState();
    // render() {
  
    const getContactDetails =  async () => {
      const headersData = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // Authorization: 'Bearer ' + value
      }
    
      try {
        const res = await fetch(`${config.baseUrl}api/fetch_contectdetails`, {
          method: 'GET',
          headers: headersData,
          // body: JSON.stringify(data)
        });
          
        const json = await res.json();
        if (json !== undefined) {
          // console.log('fetch_contectdetails res ==', res);
          console.log('fetch_contectdetails ==', json?.data);
          setContactDetails(json?.data);
          addDetail(json?.data);
        }
      } catch (e) {
        console.log('fetch_contectdetails Error ==', e);
      }  finally {
        // setLoaderStatus(false);
      }
    }
  
    useEffect(() => {
      // if() {
        getContactDetails();
      // }
    }, [])

	useEffect(() => {
		const $ = window.$;
	
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";
	
		document.body.appendChild(minscript);
	
		$('.go-top').find('a').on('click', function () {
	
			$(".quarter-overlay").fadeIn(1);
	
			$(window).scrollTop(0);
	
			setTimeout(function(){
				$(".quarter-overlay").fadeOut(300);
			}, 800);
	
		});
	
	
		$(document).on('click','.theme-btn-1 ', function(){ 
			$( 'div' ).removeClass( 'modal-backdrop' );
			$( 'div' ).removeClass( 'show' );
			$( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
		});
		
	}, [])
	// let publicUrl = process.env.PUBLIC_URL+'/'

	return (
			<footer className="ltn__footer-area  ">
				{/* <AnimatedCursor/> */}
				<InquiryModal />
				<Toaster message={"Please enter Full Name"} />
				{/* <Preloader /> */}
				<div className="footer-top-area plr--5">
				<div className="container-fluid">
					<div className="row">
					<div className="col-xl-3 col-md-6 col-sm-6 col-12">
						<div className="footer-widget footer-about-widget">
						<div className="footer-logo">
							<div className="site-logo">
							<img src={publicUrl+"assets/img/llabdhi_img/Llabdhi_Mfgr_LLP_Logo.png"} alt="Logo" width="200" />
							{/* <img src={publicUrl+"assets/img/llabdhi_img/llabdhi_logo.png"} alt="Logo" width="200" /> */}
							</div>
						</div>
						{/* <h4 className="footer-title mt-3">About Llabdhi</h4> */}

						{/* <p>Lorem Ipsum is simply dummy text of the and typesetting industry. Lorem Ipsum is dummy text of the printing.</p> */}
						</div>
					</div>
					<div className="col-xl-3 col-md-6 col-sm-6 col-12 col-xs-6">
						<div className="footer-widget footer-menu-widget clearfix">
						<h4 className="footer-title">Useful Links</h4>
						<div className="footer-menu go-top">
							<ul>
							<li><Link to="/about">About</Link></li>
							<li><Link to="/products">Products</Link></li>
							<li><Link to="/blog">Blog</Link></li>
							<li><Link to="/gallery">Gallery</Link></li>
							{/* <li><Link to="/why-us">Why Us</Link></li> */}
							{/* <li><Link to="/contact">Contact Us</Link></li> */}
							{/* <li><Link to="/about">About</Link></li>
							<li><Link to="/blog-grid">Blog</Link></li>
							<li><Link to="/shop">All Products</Link></li>
							<li><Link to="/contact">Contact</Link></li>
							<li><Link to="/faq">FAQ</Link></li>
							<li><Link to="/contact">Contact us</Link></li> */}
							</ul>
						</div>
						</div>
					</div>
					<div className="col-xl-3 col-md-6 col-sm-6 col-12 col-xs-6">
						<div className="footer-widget footer-menu-widget clearfix">
						<h4 className="footer-title">Help?</h4>
						<div className="footer-menu go-top">
							<ul>
							<li><Link to="/terms-conditions">Terms &amp; Conditions</Link></li>
							<li><Link to="/contact">Contact Us</Link></li>
							{/* <li><Link to="/">Wish List</Link></li> */}
							{/* <li><Link to="/">Login</Link></li> */}
							{/* <li><Link to="/">Checkout</Link></li> */}
							{/* <li><Link to="/terms-conditions">Privacy Policy</Link></li> */}
							{/* <li><Link to="/faq">FAQ</Link></li> */}
							{/* <li><Link to="/">Promotional Offers</Link></li> */}
							{/* <li><Link to="/cart">Cart</Link></li>
							<li><Link to="/wishlist">Wish List</Link></li>
							<li><Link to="/login">Login</Link></li>
							<li><Link to="/checkout">Checkout</Link></li>
							<li><Link to="/about">Terms &amp; Conditions</Link></li>
							<li><Link to="/shop">Promotional Offers</Link></li> */}
							</ul>
						</div>
						</div>
					</div>
					{/* <div className="col-xl-2 col-md-6 col-sm-6 col-12">
						<div className="footer-widget footer-menu-widget clearfix">
						<h4 className="footer-title">Customer Care</h4>
						<div className="footer-menu go-top">
							<ul>
							<li><Link to="/login">Login</Link></li>
							<li><Link to="/my-account">My account</Link></li>
							<li><Link to="/wishlist">Wish List</Link></li>
							<li><Link to="/add-listing">Add listing</Link></li>
							<li><Link to="/faq">FAQ</Link></li>
							<li><Link to="/contact">Contact us</Link></li>
							</ul>
						</div>
						</div>
					</div> */}
					<div className="col-xl-3 col-md-6 col-sm-12 col-12 col-xs-12">
						<div className="footer-widget footer-newsletter-widget">
						<h4 className="footer-title">Contact Details</h4>
						<div className="footer-address">
							<ul>
							<li>
								<div className="footer-address-icon">
									<i className="icon-mail" />
									
								</div>
								<div className="footer-address-info">
									<p><a href={contactDetails?.filter((o) => o?.entity_name == "Email Address")[0] ? contactDetails?.filter((o) => o?.entity_name == "Email Address")[0]?.entity_link : "mailto:info@llabdhi.com"}>{contactDetails?.filter((o) => o?.entity_name == "Email Address")[0] ? contactDetails?.filter((o) => o?.entity_name == "Email Address")[0]?.entity_data : "info@llabdhi.com"}</a></p>
								</div>
							</li>
							<li>
								<div className="footer-address-icon">
									<i className="icon-call" />
								</div>
								<div className="footer-address-info">
									<p><a href={contactDetails?.filter((o) => o?.entity_name == "Phone Number")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number")[0]?.entity_link : "tel:918655968783"}>{contactDetails?.filter((o) => o?.entity_name == "Phone Number")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number")[0]?.entity_data : "+91 86559 68783"}</a></p>
								</div>
							
							</li>
							<li>
								<div className="footer-address-icon">
									<i className="icon-call" />
								</div>
								<div className="footer-address-info">
									<p><a href={contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0]?.entity_link : "tel:912247488402"}>{contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0]?.entity_data : "+91 22 4748 8402"}</a></p>
								</div>
							</li>
							
							{/* <li>
								<div className="footer-address-icon">
									<i className="icon-placeholder" />
								</div>
								<div className="footer-address-info">
									<p><a href={contactDetails?.filter((o) => o?.entity_name == "Office Address")[0] ? contactDetails?.filter((o) => o?.entity_name == "Office Address")[0]?.entity_link : "https://maps.app.goo.gl/KufGXddb61wYKuYy6"}>{contactDetails?.filter((o) => o?.entity_name == "Office Address")[0] ? contactDetails?.filter((o) => o?.entity_name == "Office Address")[0]?.entity_data : "Llabdhi Manufacturing LLP, Prasad Chambers, 1515, Tata Rd No 2, Charni Road East, Opera House, Girgaon, Mumbai, Maharashtra 400004"}</a></p>
								</div>
							</li> */}
							</ul>
						</div>
						<div className="ltn__social-media mt-20">
							<Social />
						</div>
						{/* <h4 className="footer-title">Newsletter</h4>
						<p>Subscribe to our weekly Newsletter and receive updates via email.</p>
						<div className="footer-newsletter">
							<form action="#">
							<input type="email" name="email" placeholder="Email*" />
							<div className="btn-wrapper">
								<button className="theme-btn-1 btn" type="submit"><i className="fas fa-location-arrow" /></button>
							</div>
							</form>
						</div>
						<h5 className="mt-30">We Accept</h5>
						<img src={publicUrl+"assets/img/icons/payment-4.png"} alt="Payment Image" /> */}
						</div>
					</div>
					</div>
				</div>
				</div>
				<Copyright />
			</footer>
	)
}

const mapStateToProps = state => ({
	// error: state.login.error,
	// loginError: state.login.loginError,
	// authenticating: state.login.authenticating,
  })
  
  const mapDispatchToProps = dispatch => ({
	addDetail: (data) => dispatch(addDetail(data)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(Footer_v1);