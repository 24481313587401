import React, { useEffect, useState } from 'react';
import config from '../../config/config';
import { Placeholder } from 'rsuite';


const Gallery = () => {

	// const galleryImages = [
	//     {
	//         id: 1,
	//         images: "gallery-image_(1).jpeg",
	//     },
	// 	{
	//         id: 2,
	//         images: "gallery-image_(2).jpeg",
	//     },
	// 	{
	//         id: 3,
	//         images: "gallery-image_(3).jpeg",
	//     },
	// 	{
	//         id: 4,
	//         images: "gallery-image_(4).jpeg",
	//     },
	// 	{
	//         id: 5,
	//         images: "gallery-image_(5).jpeg",
	//     },
	// 	{
	//         id: 6,
	//         images: "gallery-image_(6).jpeg",
	//     },
	// 	// {
	//     //     id: 7,
	//     //     images: "gallery-image_(7).jpeg",
	//     // },
	// 	{
	//         id: 8,
	//         images: "gallery-image_(8).jpeg",
	//     },
	// 	{
	//         id: 9,
	//         images: "gallery-image_(9).jpeg",
	//     },
	// 	{
	//         id: 10,
	//         images: "gallery-image_(10).jpeg",
	//     },
	// 	{
	//         id: 11,
	//         images: "gallery-image_(11).jpeg",
	//     },
	// 	{
	//         id: 12,
	//         images: "gallery-image_(12).jpeg",
	//     },
	// 	{
	//         id: 13,
	//         images: "gallery-image_(13).jpeg",
	//     }
	// ];

	const $ = window.$;

	const [galleryImages, setGalleryImages] = useState([]);
	const [galleryShow, setGalleryShow] = useState(false);

	const getGalleryImages = async () => {
		const headersData = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
			// Authorization: 'Bearer ' + value
		}

		try {
			const res = await fetch(`${config.baseUrl}api/fetch_gallery_image`, {
				method: 'GET',
				headers: headersData,
				// body: JSON.stringify(data)
			});

			const json = await res.json();
			if (json !== undefined) {
				// console.log('fetch_gallery_image res ==', res);
				console.log('fetch_gallery_image ==', json?.data, " imageName === ", json?.data[1].image);
				setGalleryImages([]);
				setGalleryImages(json.data);
			}
		} catch (e) {
			console.log('fetch_gallery_image Error ==', e);
		} finally {

			// setLoaderStatus(false);
		}
	}

	useEffect(() => {
		if (galleryImages?.length == 0) {
			getGalleryImages();
		}
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			setGalleryShow(true);
			$('a[data-rel^=lightcase]').lightcase({
				transition: 'elastic', /* none, fade, fadeInline, elastic, scrollTop, scrollRight, scrollBottom, scrollLeft, scrollHorizontal and scrollVertical */
				swipe: true,
				maxWidth: 1170,
				maxHeight: 600,
			});
		}, 1100)
		// const timer = setTimeout( () => setGalleryShow(true) , 1100)
		return () => clearTimeout(timer);
	})


	function gallery() {
		return (
			<>
				{galleryImages?.map((item, index) => {
					// let imageCount = JSON.parse(item?.keyfeatures)?.length;
					// console.log("imageCount ==", imageCount,", Image: ", item);
					return (
						item?.category == "product" && 
							<figure key={index}>
								{/* <a href={publicUrl+"assets/img/gallery_img/gallery-image_(1).jpeg"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/gallery_img/"+item?.images} alt="Image" /> */}
								<a href={config.assetUrl + "uploads/gallery/" + item?.image} data-rel="lightcase:myCollection">
									<img src={config.assetUrl + "uploads/gallery/" + item?.image} alt="Image" />
								</a>
							</figure>
					)		
				})}
			</>
		)
	}

	function gallery() {
		return (
			<>
				{galleryImages?.map((item, index) => {
					// let imageCount = JSON.parse(item?.keyfeatures)?.length;
					// console.log("imageCount ==", imageCount,", Image: ", item);
					return (
						item?.category == "products" && 
							<figure key={index}>
								{/* <a href={publicUrl+"assets/img/gallery_img/gallery-image_(1).jpeg"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/gallery_img/"+item?.images} alt="Image" /> */}
								<a href={config.assetUrl + "uploads/gallery/" + item?.image} data-rel="lightcase:myCollection">
									<img src={config.assetUrl + "uploads/gallery/" + item?.image} alt="Image" />
								</a>
							</figure>
					)		
				})}
			</>
		)
	}

	function gallery2() {
		return (
			<>
				{galleryImages?.map((item, index) => {
					// let imageCount = JSON.parse(item?.keyfeatures)?.length;
					// console.log("imageCount ==", imageCount,", Image: ", item);
					return (
						item?.category == "office_culture" && 
							<figure key={index}>
								{/* <a href={publicUrl+"assets/img/gallery_img/gallery-image_(1).jpeg"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/gallery_img/"+item?.images} alt="Image" /> */}
								<a href={config.assetUrl + "uploads/gallery/" + item?.image} data-rel="lightcase:myCollection">
									<img src={config.assetUrl + "uploads/gallery/" + item?.image} alt="Image" />
								</a>
							</figure>
					)		
				})}
			</>
		)
	}

	let publicUrl = process.env.PUBLIC_URL + '/'

	return 	<div className="container">
	<div className="row">
		<div className="ltn__car-dealer-form-tab">
			<div className="ltn__tab-menu text-uppercase">
				<div className="nav">
					<a className="active show" data-bs-toggle="tab" href="#ltn__form_tab_1_1">Products</a>
					<a data-bs-toggle="tab" href="#ltn__form_tab_1_2" className>Office Culture</a>
				</div>
			</div>
			<div className="tab-content">
				<div className="tab-pane fade active show" id="ltn__form_tab_1_1">
					<div className="car-dealer-form-inner">
						<div className="ltn__img-slider-area gallery-v2 mb-6">
							<div className="container">
								<div className="w-100">
									{galleryShow ?
										galleryImages?.length != 0 &&
										gallery()

										:
										<Placeholder.Graph active width={"90%"} />
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="tab-pane fade" id="ltn__form_tab_1_2">
					<div className="car-dealer-form-inner">
						<div className="car-dealer-form-inner">
							<div className="ltn__img-slider-area gallery-v2 mb-6">
								<div className="container">
									<div className="row">
										{galleryShow ?
											galleryImages?.length != 0 &&
											gallery2()

											:
											<Placeholder.Graph active width={"90%"} />
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	
		</div>
	</div>
}


export default Gallery