import React from 'react';
import Navbar from './global-components/navbar';
import Counter from './section-components/counter-v1';
import ProSlider from './section-components/product-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import ContactFormSection from './section-components/contact-form-section';
import BannerVideo from './section-components/banner-video';
import SponsorsLogo from './section-components/sponsor-v1';
import Cateogory from './section-components/category-custom';
import { Helmet } from 'react-helmet-async';
import QualityTestSection from './section-components/quality-test-section';

const Home_V1 = () => {

    return (
    <div>
        <Helmet>
            <title>Premium Stainless Steel Suppliers & Manufacturing | Llabdhi Industries</title>
            <meta name="description" content="Discover top-quality stainless steel sheets, plates, and custom parts at Llabdhi Industries. Trusted supplier, with expertise in steel manufacturing processes." />
        </Helmet>
        <Navbar customClass="homepage" />
        <BannerVideo />
        <SponsorsLogo />
        <Counter />
        <ProSlider />
        <QualityTestSection />
        <Cateogory />
        <ContactFormSection />
        <CallToActionV1 />
        <Footer />
    </div>
)}

export default Home_V1;

