import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import config from '../../config/config';

const Sponsor = ({sectionClass}) => {
		
	let publicUrl = process.env.PUBLIC_URL+'/'
	const $ = window.$;

	const [clientLogo, setClientLogo] = useState([
		{
			id:1,
			images:"1683545487.png",
		},
		{
			id: 2,
			images:"1683545487.png",
		},
		{
			id: 3,
			images:"1683545487.png",
		},
		{
			id: 4,
			images:"1683545487.png",
		},
		{
			id: 5,
			images:"1683545487.png",
		},
		{
			id: 6,
			images:"1683545487.png",
		},
		{
			id: 7,
			images:"1683545487.png",
		},
	]);

	const getClientLogo =  async () => {
		const headersData = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
			// Authorization: 'Bearer ' + value
		}
	
		try {
			const res = await fetch(`${config.baseUrl}api/fetch_client_logo`, {
				method: 'GET',
				headers: headersData,
				// body: JSON.stringify(data)
			});
				
				const json = await res.json();
			if (json !== undefined) {
				// console.log('fetch_product res ==', res);
				console.log('fetch_client_logo ==', json?.data);
				setClientLogo(json?.data.slice(0, 8));
				loadToCarousel()
			}
		} catch (e) {
			console.log('fetch_client_logo Error ==', e);
		}  finally {
			// setLoaderStatus(false);
		}
	}

	useEffect(() => {
		// if() {
			getClientLogo();
		// }
	}, [])

	function loadToCarousel() {
	// if($('.ltn__brand-logo-active').length){
		$('.ltn__brand-logo-active').slick({
			speed: 1500,
			autoplay: true,
			autoplaySpeed: 0,
			draggable: false,
			swipe: false,
            touchMove: false,
			accesibility: false,
			swipeToSlide: false,
			centerMode: true,
			cssEase: 'linear',
			slidesToShow: 4,
			slidesToScroll: 1,
			pauseOnHover: false,
			infinite: true,
			initialSlide: 1,
			arrows: false,
			prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
			nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						arrows: false,
					}
				},
				{
					breakpoint: 580,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	};

    return <div className={"ltn__brand-logo-area ltn__brand-logo-1 pt-50 pb-50 d-none--- " + sectionClass}>
			  <div className="container-fluid">
			  	<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 secondary-color">Industries</h6>
							<h1 className="section-title"><span>Industries</span> We Serve</h1>
							{/* <p className="p-color">Lorem ipsum dolor sit amet, consectetur adipisicing</p> */}
						</div>
					</div>
				</div>
			    <div className="row ltn__brand-logo-active">
				{clientLogo?.map((item, index) => {
					const industryName = ["Architectural", "Automotive", "Fabrication", "Pharma", "White Goods", "HVAC", "Refrigeration", "Elevator"];
					industryName.reverse();
					return(
						<div className="col-lg-12">
							<div className="ltn__brand-logo-item2">
								<img src={config.assetUrl+'uploads/Client_logos/'+item?.images} alt="Brand Logo" />
								<h3>{industryName[index % industryName?.length]}</h3>
							</div>
						</div>
					)
				})}
			      {/* <div className="col-lg-12">
			        <div className="ltn__brand-logo-item">
			          <img src={config.assetUrl+'uploads/Client_logos/1702841768.jpg'} alt="Brand Logo" />
			        </div>
			      </div>
			      <div className="col-lg-12">
			        <div className="ltn__brand-logo-item">
			          <img src={config.assetUrl+'uploads/Client_logos/1702841749.jpg'} alt="Brand Logo" />
			        </div>
			      </div>
			      <div className="col-lg-12">
			        <div className="ltn__brand-logo-item">
			          <img src={config.assetUrl+'uploads/Client_logos/1702841774.jpg'} alt="Brand Logo" />
			        </div>
			      </div>
			      <div className="col-lg-12">
			        <div className="ltn__brand-logo-item">
			          <img src={config.assetUrl+'uploads/Client_logos/1702841730.jpg'} alt="Brand Logo" />
			        </div>
			      </div>
			      <div className="col-lg-12">
			        <div className="ltn__brand-logo-item">
			          <img src={config.assetUrl+'uploads/Client_logos/1702841804.png'} alt="Brand Logo" />
			        </div>
			      </div>
				  <div className="col-lg-12">
			        <div className="ltn__brand-logo-item">
			          <img src={config.assetUrl+'uploads/Client_logos/1702841800.png'} alt="Brand Logo" />
			        </div>
			      </div>
				  <div className="col-lg-12">
			        <div className="ltn__brand-logo-item">
			          <img src={config.assetUrl+'uploads/Client_logos/1702841795.jpg'} alt="Brand Logo" />
			        </div>
			      </div>
				  <div className="col-lg-12">
			        <div className="ltn__brand-logo-item">
			          <img src={config.assetUrl+'uploads/Client_logos/1702841808.png'} alt="Brand Logo" />
			        </div>
			      </div> */}
			    </div>
			  </div>
			</div>
        }

export default Sponsor