import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const PageHeaderContact = (props) => {
	let HeaderTitle = props?.headertitle;
	let publicUrl = process.env.PUBLIC_URL+'/'
	let Subheader = props?.subheader ? props?.subheader : HeaderTitle
	let CustomClass = props?.customclass ? props?.customclass : ''
	let Img = props?.Img ? props?.Img :'14.jpg'

	return (

	<div className={"ltn__breadcrumb-area ltn__breadcrumb-area-contact text-left bg-overlay-black-60 bg-image "+CustomClass} data-bs-bg={publicUrl+"assets/img/llabdhi_img/contact_header.jpg"}>
		<div className="container">
			<div className="row">
			<div className="col-lg-12">
				<div className="ltn__breadcrumb-inner text-center">
					<h1 className="page-title">{ HeaderTitle }</h1>
					<p>Do you have a question or need assistance? We are here to help you.</p>
				</div>
			</div>
			</div>
		</div>
	</div>


	)
}


export default PageHeaderContact