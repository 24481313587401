export default {
    themeColor: '#1B30A5',
    SubThemeColor: '#7265E6',
    bgColor: '#F7F9FC',
    GreenColor: '#23C4D7',
    SecondaryColor: '#7260E9',
    baseUrl: 'https://llabdhi.com/admin/api/',
    // baseUrl: 'http://127.0.0.1:8000/api/',
    baseUrl2: 'https://llabdhi.com/admin/api/',
    assetUrl: 'https://llabdhi.com/admin/public/',
    // assetUrl: 'http://127.0.0.1:8000/',
    buttonSize: 14,
    newTextSizetextSize: 16,
    AppAllTextSize: 10,
    AppAllHeaderSize: 12,
    AppAllSubTitle: 9,
    PrimaryColor: '#23C4D7',
   
    newTextSize: 14,
    textSize18: 18,
    newSmallSize: 12,
    fontFamily: {
      regular: 'Lato-Regular',
    },
    secondaryFontFamily: {
      regular: 'Roboto',
    },
    MainColor: '#120E66',
    DangerColor: '#D64B4B'
  };
  