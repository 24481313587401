import { ADD_DETAIL, GET_DETAIL, DELETE_DETAIL, EDIT_DETAIL } from "../actionTypes/actionTypes";

const addDetail = (data) => {
  return {
    type: ADD_DETAIL,
    data
  };
};

const getDetail = (data) => {
  return {
    type: GET_DETAIL,
    data
  };
};


const deleteDetail = (data) => {
  return {
    type: DELETE_DETAIL,
    data
  };
};

const editDetail = (data) => {
  return {
    type: EDIT_DETAIL,
    data
  };
};

export { addDetail, getDetail, deleteDetail, editDetail };