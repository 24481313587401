import React, { Component } from 'react';

class CategoryCustom extends Component {

    render() {

	let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__category-area ltn__product-gutter section-bg-1--- section-bg-1 mt-70 pt-60">
			  <div className="container">
			    {/* <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Our Aminities</h6>
			          <h1 className="section-title">Building Aminities</h1>
			        </div>
			      </div>
			    </div> */}
			    <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center go-top">
			      <div className="col-lg-3  text-center col-md-6 col-sm-6 col-6 mt-30 ">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center--- ">
			          <div className="card-wrapper">
			            <span className="category-icon"><img src={publicUrl+"assets/img/llabdhi_img/badge.png"} width={60} alt="llabdhi"></img></span>
			            <span className="category-title">QUALITY ASSURANCE</span>
			            <span className="category-brief">
							Our team of skilled experts, instrumental in establishing our leadership position, offers comprehensive quality testing services.
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-3  text-center col-md-6 col-sm-6 col-6 mt-30">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <div className="card-wrapper">
						<span className="category-icon"><img src={publicUrl+"assets/img/llabdhi_img/delivery-man.png"} width={60} alt="llabdhi"></img></span>
			            <span className="category-title">DELIVERY</span>
			            <span className="category-brief">
							Our logistics and infrastructure setup ensures worldwide distribution of products, whether it's across India or for international exports.
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-3  text-center col-md-6 col-sm-6 col-6 mt-30">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <div className="card-wrapper">
						<span className="category-icon"><img src={publicUrl+"assets/img/llabdhi_img/checklist.png"} width={60} alt="llabdhi"></img></span>
			            <span className="category-title">YOUR ORDERS</span>
			            <span className="category-brief">
							We are committed to efficiently processing your order to ensure timely delivery of the materials to you.
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-3  text-center col-md-6 col-sm-6 col-6 mt-30">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <div className="card-wrapper">
						<span className="category-icon"><img src={publicUrl+"assets/img/llabdhi_img/fast-delivery.png"} width={60} alt="llabdhi"></img></span>
			            <span className="category-title">QUICKEST DELIVERY TIMES</span>
			            <span className="category-brief">
							Our delivery times are among the fastest in the industry.			            
						</span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default CategoryCustom