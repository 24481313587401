import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Social extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
				{/* <li><a href="https://www.facebook.com/" title="Facebook"><i className="fab fa-facebook-f" /></a></li> */}
				{/* <li><a href="https://www.twitter.com/" title="Twitter"><svg style={{marginTop: -3}} fill="#000" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></a></li> */}
				<li><a href="https://in.linkedin.com/in/labdhimanufacturingllp" target="_blank" title="LinkedIn"><i className="fab fa-linkedin" /></a></li>
				<li><a href="https://www.instagram.com/llabdhigroup/" target="_blank" title="Instagram"><i className="fab fa-instagram" /></a></li>
				{/* <li><a href="https://www.dribble.com/" title="Dribbble"><i className="fab fa-dribbble" /></a></li> */}
			</ul>
		</div>
        }
}

export default Social