import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const PageHeaderAnimated = (props) => {
	let HeaderTitle = props?.headertitle;
	let publicUrl = process.env.PUBLIC_URL+'/'
	let Subheader = props?.subheader ? props?.subheader : HeaderTitle
	let CustomClass = props?.customclass ? props?.customclass : ''
	let Img = props?.Img ? props?.Img :'14.jpg'

	return (

	<div className={"ltn__breadcrumb-area text-left bg-overlay-black-60 bg-image "+CustomClass} data-bs-bg={publicUrl+"assets/img/llabdhi_img/products_bg_image.png"}>
		<div className="container">
			<div className="ltn__breadcrumb-list">
				<ul>
				<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
				<li>{ Subheader }</li>
				</ul>
			</div>
			<div className="row">
			<div className="col-lg-12">
				<div className="ltn__breadcrumb-inner text-center page-title-animated">
					<h1 className="page-title">Crafting Steel</h1> 
					<h1 className="page-title"> 
						<span>Excellence</span>
					</h1>
					{/* <h1 className="page-title">You Ideate,</h1> 
					<h1 className="page-title"> 
					<span>We Create.</span>
					</h1> */}

					{/* <h1 className="page-title">You Ideate, We Create</h1> */}
					{/* <p></p> */}
				</div>
			</div>
			</div>
		</div>
	</div>


	)
}


export default PageHeaderAnimated