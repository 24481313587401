import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const CoreValues = () => {

	let publicUrl = process.env.PUBLIC_URL+'/';
	const [data, setData] = useState([
		{
			id: 1,
			iconImage: "transparency.png",
			cardTitle: "Transparency",
			cardDesc: "We believe in the power of openness and honesty. Transparency isn't just a principle for us; it's the foundation of trust that we build with each other, our clients, and our partners.",
		},
		{
			id: 2,
			iconImage: "award.png",
			cardTitle: "Meritocracy",
			cardDesc: "We're firm believers in recognizing talent and hard work. It's not about who you know; it's about what you bring to the table. Our merit-based approach ensures that everyone has a fair chance to shine.",
		},
		{
			id: 3,
			iconImage: "people.png",
			cardTitle: "Respect for Individuals",
			cardDesc: "Each person matters, and each voice is heard. We embrace diversity and inclusivity, fostering an environment where everyone feels valued, respected, and empowered to be their best selves.",
		},
		{
			id: 4,
			iconImage: "brain.svg",
			cardTitle: "Integrity",
			cardDesc: "We operate with unwavering honesty, transparency, and ethical conduct in all we do. It's not just a value; it's our way of doing business, earning the trust and respect of all stakeholders.",
		},
	]);

    return (
		<div className="ltn__core-values-section pt-50 pb-60 go-top">
				<div className="container">
					<div className="row">
						{/* <div className="col-lg-4 align-self-center">
							<div className="about-us-img-wrap">
								<img src={publicUrl+"assets/img/llabdhi_img/core_values.png"} alt="Image" />
							</div>
						</div> */}
						<div className="col-lg-12 align-self-center fade-in-up">
							<div className="about-us-info-wrap ml-lg-50">
								<div className="section-title-area ltn__section-title-2--- mb-20">
									<h6 className="section-subtitle section-subtitle-2 secondary-color">About Us</h6>
									<h1 className="section-title">Our<span> Core Values</span></h1>
									<p>Our core values are the foundation of everything we do. They are the guiding principles that shape our company's culture and drive our decision-making.</p>
								</div>

								<div className="box-cards-container">
									{data?.map((item) => {
										return (
											<div className="cards" key={item?.id}>
												<div className="first-container">
													<div className="icon-container">
														<img src={publicUrl+"assets/img/llabdhi_img/"+item?.iconImage} alt="Image" width={50} />
													</div>
													<h4 className="card-heading">{item?.cardTitle}</h4>
												</div>
												<div className="second-container">
													<p>
													{item?.cardDesc}
													</p>
												</div>
											</div>
										)
									})}

								</div>

								{/* <div className="about-us-info-wrap-inner about-us-info-devide---">
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
								</div> */}
								{/* <div className="btn-wrapper animated">
								<Link to="/about" className="theme-btn-1 btn btn-effect-1 text-uppercase">About Us</Link>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>	
	)
}

export default CoreValues