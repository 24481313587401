import { combineReducers, createStore } from "redux";
import cartReducer from "../reducers/cartReducer";
import contactsReducer from "../reducers/contactsReducer";

const store = createStore(combineReducers({
    cartReducer: cartReducer, 
    contactsReducer: contactsReducer,
}));
// const store = createStore(cartReducer);

export default store;

// import { combineReducers, createStore } from "redux";
// import cartReducer from '../reducers/cartReducer';

// const rootReducer = combineReducers({
//     cartReducer : cartReducer,
// })

// const store = () => createStore(rootReducer);
// export default store;