import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const BannerVideo = () => {

	let publicUrl = process.env.PUBLIC_URL + '/'
	let imagealt = 'image'

	return <div className="ltn__slider-area ltn__slider-4 position-relative ltn__primary-bg margin-t-header">
		{/* <div className="top-btn">
			<Link to="/contact">Let's Talk...</Link>
		</div> */}
		<div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
			<div className="video-overlay"></div>
			{/* HTML5 VIDEO */}
			<video autoPlay muted loop id="myVideo">
				<source src={publicUrl + "assets/img/llabdhi_img/Llabdhi-Cover-Video.mp4"} type="video/mp4" />
			</video>
			<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-10---" data-bs-bg="img/slider/41.jpg">
				<div className="ltn__slide-item-inner text-center">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12 align-self-center">
								<div className="slide-item-info">

									{/* <div className="hero-section-title-tag">
										<h1>
											<span>Precision.</span>&nbsp;<span>Quality.</span>&nbsp;<span>Excellence.</span>&nbsp;<span>Llabdhi.</span>
										</h1>
									</div> */}
									
									<div className="slide-item-info-inner ltn__slide-animation">
									<h1 className="">Welcome to <span>Llabdhi Manufacturing LLP</span></h1>
										<h6 className="slide-sub-title white-color--- animated">
											{/* <span><i className="fas fa-home" /></span>  */}
											LLABDHI offers convenient, customized just-in-time services to the customer's doorstep. The company specializes in the business of processing and distribution of stainless steel to serve its valued customers with exact Slit, Cut-to-Size, Polished Stainless Steel Sheets, Coils and Blanks conforming to highest standards of processing tolerances.
										</h6>
										{/* <h1 className="slide-title animated ">LLABDHI offers <span>convenient, customized
											just-in-time</span> services to the
											customer's doorstep.
										</h1> */}
										{/* <div className="slide-brief animated">
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
						</div> */}
										<div className="btn-wrapper animated ">
											<Link className="theme-btn-1 btn btn-effect-1 go-top" to="/shop">View Products</Link>
											{/* <a className="ltn__video-play-btn bg-white" href="https://www.youtube.com/embed/HnbMYzdjuBs?autoplay=1&showinfo=0" data-rel="lightcase">
								<i className="icon-play  ltn__secondary-color" />
							</a> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default BannerVideo