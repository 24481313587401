import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Select from "react-select";
import config from '../../config/config';
import { toast } from 'react-toastify';

const ContactFormSection = () => {

	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const [serviceType, setServiceType] = useState(0);
	const [message, setMessage] = useState();
	const [selectedFile, setSelectedFile] = useState(null);
	// const [selectedFileName, setSelectedFileName] = useState(null);

	const handleFileUpload = (event) => {
		// setSelectedFileName(event.target.files[0]);
		setSelectedFile(event.target.files[0]);
	};

	let publicUrl = process.env.PUBLIC_URL + '/'


	const validate = () => {
		return !(
			!name ||
			name?.trim().length === 0 ||
			!phone ||
			phone?.trim().length === 0 ||
			phone?.trim().length > 12 ||
			!email ||
			email?.trim().length === 0 ||
			// email?.trim().length < 6 ||
			// email?.indexOf("@") < 0 ||
			email?.indexOf(" ") >= 0 ||	
			!serviceType ||
			serviceType === 0 ||
			!message ||
			message?.trim().length === 0
		);
	};

	const submit = (event) => {
		
		event.preventDefault();
		
		// console.log(name, phone, email, company, qty, address, zipCode, requestedData);
		console.log("testing");
		 if (!validate()) {
			if (!name || name?.trim().length === 0)  return toast("Please enter full name", {type: "error"});
			if (!email || email?.trim().length === 0 || email?.trim().length < 6 || email?.indexOf("@") < 0 || email?.indexOf(" ") >= 0)  return toast('Please enter valid email address', {type: "error"});
			if (!phone || phone?.trim().length === 0 ||  phone?.trim().length > 12) return  toast('Please enter valid phone number', {type: "error"});
			// if (!company || company?.trim().length === 0) return toast('Please enter Company Name', {type: "error"});
			// if (!expDate || expDate?.trim().length === 0) return toast('Please enter Expected Date', {type: "error"});
			// if (!city || city?.trim().length === 0) return toast('Please enter City', {type: "error"});
			// if (!state || state?.trim().length === 0) return toast('Please enter State', {type: "error"});
			if (!serviceType || serviceType === 0) return toast('Please select service type', {type: "error"});
			if (!message || message?.trim().length === 0) return toast('Please enter message', {type: "error"});
		}

		console.log("testing");
	
		const formData = new FormData();
		// document.getElementById('corporate-inquiry-modal').modal('hide')
		formData.append('name', name?.trim());
		formData.append('email', email?.trim());
		formData.append('phone', phone?.trim());
		formData.append('enquiry_about', serviceType);
		formData.append('cv', selectedFile);
		formData.append('message', message?.trim());
		
		// const data = {
		// 	name: name?.trim(),
		// 	email: email?.trim(),
		// 	phone: phone?.trim(),
			
		// 	service: serviceType,
		// 	message: message?.trim(),
		// };

		return makeGeneralEnquiry(formData);
	}

	const makeGeneralEnquiry = async (data) => {
		// data.preventDefault();
		try {
			const res = await fetch(`${config.baseUrl}api/add-general-enquiry`, {
				mode: 'no-cors',
				method: 'POST',
				headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				},
				body: data,
			
			});
				// body: JSON.stringify(data),
			const json = await res.json();
			console.log("add-general-enquiry json ===", json);
			if (json !== undefined) {
			// if (resJson.status === 200 || resJson.status === 201) {
				resetForm();
				toast("Form submitted successfully!", {type: "success"});
			}
		// } else {
			
		// //   setMessage("Some error occured");
		// }
		} catch (err) {
			toast("Something went wrong!", {type: "error"});
			console.log(err);
		} finally {
			// setShow(false);
			// closePopup();
		}
	};

	function resetForm() {
		document.getElementById("contact-form").reset();
		setName();
		setEmail();
		setPhone();
		setSelectedFile(null);
		setServiceType(0);
		setMessage("");
		return;
	}

	// const [details, setDetails] = useState();

	// useEffect(()=> {
	// 	setDetails(conDetail);
	// }, [conDetail]);

	return <div className="ltn__contact-form-section pt-50 pb-30">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-lg-6 fade-in-up">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-30">
							<h6 className="section-subtitle section-subtitle-2 secondary-color">Contact Us</h6>
							<h1 className="section-title">Request for more information</h1>
							{/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod  incididunt ut labore et dolore magna aliquaveniam, quis  exercitation ullamco. Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod  incididunt ut labore et dolore magna aliquaveniam, quis nostrud exercitation ullamco.</p>
							<div className="d-flex flex-row mt-3">
								<div>
									<img src={publicUrl + "assets/img/llabdhi_img/person.jpg"} alt="#" />
								</div>
								<div className="ms-3 person-info">
									<div className="person-name">William J. Smith</div>
									<div className="designation">Support Engineer</div>
									<div>
										<a className="d-flex align-item-center mt-2" href="">
											<div className="me-2"><i className="icon-call"></i></div>
											<div>(+844) - 1875 333 469</div>
										</a>
									</div>
									<div>
										<a className="d-flex align-item-center" href="">
											<div className="me-2"><i className="icon-mail"></i></div>
											<div>info@llabdhi.com</div>
										</a>
									</div>
								</div>
							</div> */}
						</div>

					</div>
				</div>
				<div className="col-lg-6 align-self-center">
					<form id="contact-form" encType="multipart/form-data" action="">
					{/* <form id="contact-form" action={publicUrl + "mail.php"} method="post"> */}
						<div className="row">
							<div className="col-md-6 col-xs-6">
								<div className="input-item input-item-name ltn__custom-icon">
									<input type="text" name="name" placeholder="Name" onChange={(e) => setName(e.target.value)} required />
								</div>
							</div>
							<div className="col-md-6 col-xs-6">
								<div className="input-item input-item-email ltn__custom-icon">
									<input type="email" name="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} required />
								</div>
							</div>
							<div className="col-md-6 col-xs-6">
								<div className="input-item" >									
									<Select type="select" name="serviceType" 
										options={[
											{ label: "Select Service Type", value: 0 },
											{ label: "Manufacturing", value: 1 },
											{ label: "Wholeseller", value: 2 },
											{ label: "Career", value: 3 },
											{ label: "Collaborator", value: 4 },
											{ label: "Service Enquiry", value: 5 }
										]}
										onChange={(item) => {
											console.log("item?.label ===", item?.label);
											if(item?.label != "Career") setSelectedFile(null);
											setServiceType(item?.label);
										}} 
										required
										className="custom-dropdown"
										classNamePrefix="normal"
										// style={{height: 40}}
									>
									</Select>
								</div>
							</div>
							<div className="col-md-6 col-xs-6">
								<div className="input-item input-item-phone ltn__custom-icon">
									<input type="text" name="phone" placeholder="Contact Number" onChange={(e) => setPhone(e.target.value)} required />
								</div>
							</div>
						</div>
						{serviceType == "Career" &&
							 <div className="mb-4">
								<h3>Upload CV</h3>
								<input type="file" onChange={handleFileUpload} />
								{/* <button onClick={handleUpload}>Upload</button> */}
						   </div>
						}
						<div className="input-item input-item-textarea ltn__custom-icon">
							<textarea name="message" placeholder="Message" defaultValue={""} onChange={(e) => setMessage(e.target.value)} required />
						</div>
						{/* <p><label className="input-info-save mb-0"><input type="checkbox" name="agree" required /> Save my name, email, and website in this browser for the next time I comment.</label></p> */}
						<div className="btn-wrapper mt-0">
							<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit" onClick={submit}>Send Message</button>
						</div>
						<p className="form-messege mb-0 mt-20" />
					</form>
				</div>
			</div>
		</div>
	</div>
}

export default ContactFormSection