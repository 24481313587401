import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Comments from './comments';
import Sidebar from './sidebar';
import config from '../../config/config';
import parse from 'html-react-parser';
import moment from 'moment/moment';

const BlogDetails = ({ blogDetails}) => {
	

	let publicUrl = process.env.PUBLIC_URL + '/'
	let imagealt = 'image'

	return (
		<div className="ltn__page-details-area ltn__blog-details-area mb-120">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__blog-details-wrap">
							<div className="ltn__page-details-inner ltn__blog-details-inner">
								{/* <div className="ltn__blog-meta">
									<ul>
										<li className="ltn__blog-category">
											<Link to="/shop">{blogDetails?.name}</Link>
										</li>
									</ul>
								</div> */}
								<h2 className="ltn__blog-title">{blogDetails?.title}</h2>
								<div className="ltn__blog-meta">
									<ul>
										<li className="ltn__blog-date">
											<i className="far fa-calendar-alt" /> {moment(blogDetails?.created_at).format('MMM DD, YYYY')}
										</li>
									</ul>
								</div>
								<img src={blogDetails?.image ? config.assetUrl + "uploads/blog_images/" + blogDetails?.image :  publicUrl + "assets/img/llabdhi_img/core_feature_image.jpg"} alt="Image" />

								{blogDetails?.longdescription ? parse(blogDetails?.longdescription) : ""}
							</div>
							{/* <hr /> */}
							{/* prev-next-btn */}
							{/* <div className="ltn__prev-next-btn row mb-50">
								<div className="blog-prev col-lg-6">
									{blogDetails && blogs[parseInt(blogs.findIndex((element) => element.id === id)) - 1]['id'] &&
										<>
											<h6>Go Previous</h6>
											<h3 className="ltn__blog-title"><Link to={{ pathname: `/blog-details/${blogs[parseInt(blogs.findIndex((element) => element.id === id)) - 1].id}`, state: (blogs[parseInt(blogs.findIndex((element) => element.id === id)) - 1].id) }}>Prev Blog</Link></h3>
										</>
									}
								</div> */}
								{/* <div className="blog-prev col-lg-6">
									{blogList && blogList[blogList?.findIndex((element) => element.id = blogDetails.id)-1]?.id &&
										<>
											<h6>Go Previous</h6>
											<h3 className="ltn__blog-title"><Link to={{ pathname: `/blog-details/${blogList[blogList.findIndex((element) => element.id = id)-1].id}`, state: (blogList[blogList.findIndex((element) => element.id = id)-1].id) }}>Prev Blog</Link></h3>
										</>
									}
								</div> */}
								{/* <div className="blog-prev blog-next text-right text-end col-lg-6">
									{blogList && blogList[blogList?.findIndex((element) => element.id = blogDetails.id)+1]?.id &&
										<>
											<h6>Go Next</h6>
											<h3 className="ltn__blog-title"><Link to={{ pathname: `/blog-details/${blogList[blogList.findIndex((element) => element.id = id)+1].id}`, state: (blogList[blogList.findIndex((element) => element.id = id)+1].id) }}>Next Blog</Link></h3>
											 <h3 className="ltn__blog-title"><Link to={{ pathname: `/blog-details/${blogDetails?.id+1}`, state: (blogDetails?.id+1) }}>Next Blog</Link></h3> 
										</>
									}
								</div> */}
							{/* </div> */}
							{/* <hr /> */}
							{/* related-post */}
							{/* <div className="related-post-area mb-50">
								<h4 className="title-2">Related Post</h4>
								<div className="row">
									<div className="col-md-6">
										
										<div className="ltn__blog-item ltn__blog-item-6">
											<div className="ltn__blog-img">
												<Link to="/blog-details"><img src={publicUrl + "assets/img/blog/blog-details/11.jpg"} alt="Image" /></Link>
											</div>
											<div className="ltn__blog-brief">
												<div className="ltn__blog-meta">
													<ul>
														<li className="ltn__blog-date ltn__secondary-color">
															<i className="far fa-calendar-alt" />June 22, 2020
														</li>
													</ul>
												</div>
												<h3 className="ltn__blog-title"><Link to="/blog-details">A series of iOS 7 inspire
													vector icons sense.</Link></h3>
												<p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										
										<div className="ltn__blog-item ltn__blog-item-6">
											<div className="ltn__blog-img">
												<Link to="/blog-details"><img src={publicUrl + "assets/img/blog/blog-details/12.jpg"} alt="Image" /></Link>
											</div>
											<div className="ltn__blog-brief">
												<div className="ltn__blog-meta">
													<ul>
														<li className="ltn__blog-date ltn__secondary-color">
															<i className="far fa-calendar-alt" />June 22, 2020
														</li>
													</ul>
												</div>
												<h3 className="ltn__blog-title"><Link to="/blog-details">A series of iOS 7 inspire
													vector icons sense.</Link></h3>
												<p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</div>
					{/* <Sidebar /> */}
				</div>
			</div>
		</div>
	)
}


export default BlogDetails;
