import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter, Route, Switch, Navigate } from "react-router-dom";
import HomeV1 from './components/home-v1';
import About from './components/about';
import Service from './components/service';
import ServiceDetails from './components/service-details';
import Team from './components/team';
import Faq from './components/faq';
import Error  from './components/404';
import Location  from './components/location';
import ProductDetails from './components/product-details';
import ShopLeftSidebar from './components/shop-left-sidebar';
import Blog from './components/blog';
import BlogDetails from './components/blog-details';
import Contact from './components/contact';
import GalleryPage from './components/gallery';
import { Provider } from "react-redux";
import store from './config/store';
import TermsConditions from './components/terms-conditions';
import { HelmetProvider } from 'react-helmet-async';
import QualityTesting from './components/quality-testing';

class Root extends Component {
    
    render() {
        return(
                  <BrowserRouter basename="/">
                      {/* <HashRouter basename="/"> */}
	                <div>
                        <Switch>
                            <Route exact path="/" component={() => <HomeV1 />} />
                            <Route path="/about" component={About} />
                            <Route path="/gallery" component={GalleryPage} />
                            <Route path="/service" component={Service} />
                            <Route path="/service-details" component={ ServiceDetails } />
                            <Route path="/team" component={ Team } />
                            <Route path="/faq" component={ Faq } />
                            <Route path="/404" component={ Error } />
                            <Route path="/location" component={ Location } />
                            <Route path="/shop" component={ ShopLeftSidebar } />
                            <Route exact={true} path="/product-details/:slug" component={ ProductDetails } />
                            <Route path="/blog" component={ Blog } />
                            <Route path="/blog-details/:slug" component={props => <BlogDetails {...props} /> } />
                            <Route path="/terms-conditions" component={ TermsConditions } />
                            {/* <Route path="/quality-test" component={ QualityTesting } /> */}
                            <Route path="/contact" component={ Contact } />
                            <Route path="*" component={Error} />
                        </Switch>
	                </div>
                      {/* </HashRouter> */}
                  </BrowserRouter> 
              
        )
    }
}

export default Root;

const helmetContext = {};

ReactDOM.render(
    <Provider store={store}>
        <HelmetProvider context={helmetContext}>
            <Root />
        </HelmetProvider>
    </Provider>
, document.getElementById('quarter'));
