import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BlogDetails from './blog-components/blog-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import config from '../config/config';
import { Helmet } from 'react-helmet-async';

const BlogDetailsPage = () => {
    const { slug } = useParams();

    console.log("blogdetailspage === ", slug);

    // var productId = 0;
    // {
    //     slug == "2bba-mill-finish" ? productId = 5 :
    //     slug == "hairline-finish-stainless-steel-sheets" ? productId = 6 :
    //     slug == "super-mirror-sheet" ? productId = 7 :
    //     slug == "stainless-steel-strips" ? productId = 8 :
    //     productId = 9
    // }

    const [blogs, setBlogs] = useState([]);
	const [blogDetails, setBlogDetails] = useState();
	var blogList = [];

	const getBlogDetails =  async () => {
		const headersData = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
			// Authorization: 'Bearer ' + value
		}

		try {
			const res = await fetch(`${config.baseUrl}api/fetch-blogs`, {
				method: 'GET',
				headers: headersData,
				// body: JSON.stringify(data)
			});
				
				const json = await res.json();
			if (json !== undefined) {
				// console.log('fetch-blogs res ==', res);
				console.log('fetch-blogs ==', json?.data);
				setBlogs([]);
				setBlogs(json.data);
				blogList = json.data;
			}
		} catch (e) {
			console.log('fetch-blogs Error ==', e);
		}  finally {
			// loadToCarousel2()
			// setLoaderStatus(false);
		}

		try {
			const res = await fetch(`${config.baseUrl}api/fetch_blog_by_id/${blogList?.find((x) => x.slug === slug)?.id}`, {
				method: 'GET',
				headers: headersData,
				// body: JSON.stringify(data)
			});
				
				const json = await res.json();
			if (json !== undefined) {
				// console.log('fetch_product res ==', res);
				console.log('fetch_blog_by_id ==', json?.data);
				setBlogDetails();
				setBlogDetails(json.data);
			}
		} catch (e) {
			console.log('fetch_blog_by_id Error ==', e);
		}  finally {
			// loadToCarousel()
			// loadToCarousel()
			// setLoaderStatus(false);
		}
		// console.log("blog details Id ===", blogList, "array1.find((element) => element > 10); ===");

		// console.log("blogList[blogList?.findIndex((element) => element.id = blogDetails.id)-1]?.id  ===", JSON.stringify(blogList) , "array1.find((element) => element > 10); ===", blogList[parseInt(blogList.findIndex((element) => element.id === id)) - 1].id);
		// console.log("blog details Id ===", id, "array1.find((element) => element > 10); ===", blogs[(blogs.findIndex((element) => element.id = id)) - 1]);

	}

	useEffect(() => {
		// setProducts([]);
		setBlogDetails();
		getBlogDetails();
		// console.log("id ==", id, ", url ==", url, ", pathname ==", pathname)
		// if(blogDetails) {
		
		// }
	}, [])
    
    return <div>
		{/* <Helmet>
            <title>{blogDetails ? blogDetails?.meta-title : "hello"}</title>
            <meta name='description' content={blogDetails ? blogDetails?.meta-description : "hello"} />
        </Helmet> */}
        <Navbar />
        <PageHeader headertitle="Blog Details" />
        <BlogDetails blogDetails={blogDetails} />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default BlogDetailsPage

