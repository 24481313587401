import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import config from '../../config/config';
import Select from "react-select";
import { toast } from 'react-toastify';

const ContactForm = ({contactDetailsRedux}) => {

	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const [serviceType, setServiceType] = useState(0);
	const [message, setMessage] = useState();
	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileUpload = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const $ = window.$;
	var form = $('#contact-form');

	// Get the messages div.
	var formMessages = $('.form-messege');


	const [contactDetails, setContactDetails] = useState();
    // render() {
  
    const getContactDetails =  async () => {
      const headersData = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // Authorization: 'Bearer ' + value
      }
    
      try {
        const res = await fetch(`${config.baseUrl}api/fetch_contectdetails`, {
          method: 'GET',
          headers: headersData,
          // body: JSON.stringify(data)
        });
          
        const json = await res.json();
        if (json !== undefined) {
          // console.log('fetch_contectdetails res ==', res);
          console.log('fetch_contectdetails ==', json?.data);
          setContactDetails(json?.data);
        }
      } catch (e) {
        console.log('fetch_contectdetails Error ==', e);
      }  finally {
        // setLoaderStatus(false);
      }
    }

	useEffect(() => {
        getContactDetails();

		console.log("contactDetailsRedux === ", contactDetailsRedux);
		// $('#contact-form').slick({
		// 	autoplay: false,
		// 	autoplaySpeed: 2000,
		// 	arrows: false,
		// 	dots: true,
		// 	fade: true,
		// 	cssEase: 'linear',
		// 	infinite: true,
		// 	speed: 300,
		// 	slidesToShow: 1,
		// 	slidesToScroll: 1,
		// 	prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
		// 	nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
		// 	responsive: [
		// 		{
		// 			breakpoint: 1200,
		// 			settings: {
		// 				arrows: false,
		// 				dots: true,
		// 			}
		// 		}
		// 	]
		// })

	// 	$(form).submit(function (e) {
	// 		// Stop the browser from submitting the form.
	// 		e.preventDefault();

	// 		// Serialize the form data.
	// 		var formData = $(form).serialize();

	// 		// Submit the form using AJAX.
	// 		$.ajax({
	// 			type: 'POST',
	// 			url: $(form).attr('action'),
	// 			data: formData
	// 		})
	// 			.done(function (response) {
	// 				// Make sure that the formMessages div has the 'success' class.
	// 				$(formMessages).removeClass('error');
	// 				$(formMessages).addClass('success');

	// 				// Set the message text.
	// 				$(formMessages).text(response);

	// 				// Clear the form.
	// 				$('#contact-form input,#contact-form textarea').val('');
	// 			})
	// 			.fail(function (data) {
	// 				// Make sure that the formMessages div has the 'error' class.
	// 				$(formMessages).removeClass('success');
	// 				$(formMessages).addClass('error');

	// 				// Set the message text.
	// 				if (data.responseText !== '') {
	// 					$(formMessages).text(data.responseText);
	// 				} else {
	// 					$(formMessages).text('Oops! An error occured and your message could not be sent.');
	// 				}
	// 			});
	// 	});

	}, [])


	const validate = () => {
		return !(
			!name ||
			name?.trim().length === 0 ||
			!phone ||
			phone?.trim().length === 0 ||
			phone?.trim().length > 12 ||
			!email ||
			email?.trim().length === 0 ||
			// email?.trim().length < 6 ||
			// email?.indexOf("@") < 0 ||
			email?.indexOf(" ") >= 0 ||	
			!serviceType ||
			serviceType === 0 ||
			!message ||
			message?.trim().length === 0
		);
	};

	const submit = (event) => {
		
		event.preventDefault();
		
		// console.log(name, phone, email, company, qty, address, zipCode, requestedData);
		console.log("testing");
		 if (!validate()) {
			if (!name || name?.trim().length === 0)  return toast("Please enter full name", {type: "error"});
			if (!email || email?.trim().length === 0 || email?.trim().length < 6 || email?.indexOf("@") < 0 || email?.indexOf(" ") >= 0)  return toast('Please enter valid email address', {type: "error"});
			if (!phone || phone?.trim().length === 0 ||  phone?.trim().length > 12) return  toast('Please enter valid phone number', {type: "error"});
			// if (!company || company?.trim().length === 0) return toast('Please enter Company Name', {type: "error"});
			// if (!expDate || expDate?.trim().length === 0) return toast('Please enter Expected Date', {type: "error"});
			// if (!city || city?.trim().length === 0) return toast('Please enter City', {type: "error"});
			// if (!state || state?.trim().length === 0) return toast('Please enter State', {type: "error"});
			if (!serviceType || serviceType === 0) return toast('Please select service type', {type: "error"});
			if (!message || message?.trim().length === 0) return toast('Please enter message', {type: "error"});
		}

		console.log("testing");
	
		const formData = new FormData();
		// document.getElementById('corporate-inquiry-modal').modal('hide')
		formData.append('name', name?.trim());
		formData.append('email', email?.trim());
		formData.append('phone', phone?.trim());
		formData.append('enquiry_about', serviceType);
		formData.append('cv', selectedFile);
		formData.append('message', message?.trim());
		
		// const data = {
		// 	name: name?.trim(),
		// 	email: email?.trim(),
		// 	phone: phone?.trim(),
			
		// 	service: serviceType,
		// 	message: message?.trim(),
		// };

		return makeGeneralEnquiry(formData);
	}

	const makeGeneralEnquiry = async (data) => {
		// data.preventDefault();
		try {
			const res = await fetch(`${config.baseUrl}api/add-general-enquiry`, {
				mode: 'no-cors',
				method: 'POST',
				headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				},
				body: data,
			
			});
				// body: JSON.stringify(data),
			// const json = await res.json();
			console.log("add-general-enquiry json ===", res);
			// if (json !== undefined) {
			// if (resJson.status === 200 || resJson.status === 201) {
				resetForm();
				toast("Form submitted successfully!", {type: "success"});
			// }
		// } else {
			
		// //   setMessage("Some error occured");
		// }
		} catch (err) {
			toast("Something went wrong!", {type: "error"});
			console.log(err);
		} finally {
			// setShow(false);
			// closePopup();
		}
	};

	function resetForm() {
		document.getElementById("contact-form").reset();
		setName();
		setEmail();
		setPhone();
		setSelectedFile(null);
		setServiceType(0);
		setMessage("");
		return;
	}


		// Get the form.
		
		// Set up an event listener for the contact form.
		
	// }


		let publicUrl = process.env.PUBLIC_URL + '/'

		return (
			<div className="ltn__contact-message-area mb-120 mb--100">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="contact-form-box ltn__form-box box-shadow white-bg">
								<div className="row">
									<div className="col-lg-6">
										<div className="justify-xs-content-center d-xs-flex flex-xs-column">
											<h4 className="title-2">Feel free to contact us <br />for any query.</h4>
											<div className="flex-column text-xs-center">
												<div className="contact_item_container">
													<div className="contact_icon_container"><img src={publicUrl + "assets/img/llabdhi_img/phone_contact.png"} alt="llabdhi" /></div>
													<div className="details_item_container">
														<div className="contact_detail_heading">Phone Number:</div>
														<div><a href={contactDetails?.filter((o) => o?.entity_name == "Phone Number")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number")[0]?.entity_link : "tel:912235041652"}>{contactDetails?.filter((o) => o?.entity_name == "Phone Number")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number")[0]?.entity_data : "+91 22 3504 1652"}</a></div>
														<div><a href={contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0]?.entity_link : "tel:912247488402"}>{contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0]?.entity_data : "+91 22 4748 8402"}</a></div>
													</div>
													{/* <p><a href={contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0]?.entity_link : "912247488402"}>{contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0] ? contactDetails?.filter((o) => o?.entity_name == "Phone Number 2")[0]?.entity_data : "+91 22 4748 8402"}</a></p> */}
												</div>
												<div className="contact_item_container">
													<div className="contact_icon_container"><img src={publicUrl + "assets/img/llabdhi_img/mail_contact.png"} alt="llabdhi" /></div>
													<div className="details_item_container">
														<div className="contact_detail_heading">Mail Address:</div>
														<div><a href={contactDetails?.filter((o) => o?.entity_name == "Email Address")[0] ? contactDetails?.filter((o) => o?.entity_name == "Email Address")[0]?.entity_link : "mailto:info@llabdhi.com"}>{contactDetails?.filter((o) => o?.entity_name == "Email Address")[0] ? contactDetails?.filter((o) => o?.entity_name == "Email Address")[0]?.entity_data : "info@llabdhi.com"}</a></div>
													</div>
												</div>
												<div className="contact_item_container">
													<div className="contact_icon_container"><img src={publicUrl + "assets/img/llabdhi_img/address_contact.png"} alt="llabdhi" /></div>
													<div className="details_item_container">
														<div className="contact_detail_heading">Head Office Address:</div>
														<div><a href={contactDetails?.filter((o) => o?.entity_name == "Office Address")[0] ? contactDetails?.filter((o) => o?.entity_name == "Office Address")[0]?.entity_link : "https://maps.app.goo.gl/KufGXddb61wYKuYy6"}>{contactDetails?.filter((o) => o?.entity_name == "Office Address")[0] ? contactDetails?.filter((o) => o?.entity_name == "Office Address")[0]?.entity_data : "Llabdhi Manufacturing LLP, Prasad Chambers, 1515, Tata Rd No 2, Charni Road East, Opera House, Girgaon, Mumbai, Maharashtra 400004"}</a></div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="col-lg-6">
										<div className="  ">
											{/* <h4 className="title-2">Get A Quote</h4> */}
											<form id="contact-form" encType="multipart/form-data" action="">
											{/* <form id="contact-form" action={publicUrl + "mail.php"} method="post"> */}
												<div className="row">
													<div className="col-md-6 col-xs-6">
														<div className="input-item input-item-name ltn__custom-icon">
															<input type="text" name="name" placeholder="Name" onChange={(e) => setName(e.target.value)} required />
														</div>
													</div>
													<div className="col-md-6 col-xs-6">
														<div className="input-item input-item-email ltn__custom-icon">
															<input type="email" name="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} required />
														</div>
													</div>
													<div className="col-md-6 col-xs-6">
														<div className="input-item" >									
															<Select type="select" name="serviceType" 
																options={[
																	{ label: "Select Service Type", value: 0 },
																	{ label: "Manufacturing", value: 1 },
																	{ label: "Wholeseller", value: 2 },
																	{ label: "Career", value: 3 },
																	{ label: "Collaborator", value: 4 },
																	{ label: "Service Enquiry", value: 5 }
																]}
																onChange={(item) => {
																	console.log("item?.label ===", item?.label);
																	if(item?.label != "Career") setSelectedFile(null);
																	setServiceType(item?.label);
																}} 
																required
																className="custom-dropdown"
																classNamePrefix="normal"
																style={{border: 1,borderWidth: 1, borderType: 'solid', borderColor: "#000"}}
															>
															</Select>
														</div>
													</div>
													<div className="col-md-6 col-xs-6">
														<div className="input-item input-item-phone ltn__custom-icon">
															<input type="text" name="phone" placeholder="Contact Number" onChange={(e) => setPhone(e.target.value)} required />
														</div>
													</div>
												</div>
												{serviceType == "Career" &&
													<div className="mb-4">
														<h3>Upload CV</h3>
														<input type="file" onChange={handleFileUpload} />
													</div>
												}
												<div className="input-item input-item-textarea ltn__custom-icon">
													<textarea name="message" placeholder="Message" defaultValue={""} onChange={(e) => setMessage(e.target.value)} required />
												</div>
												<div className="btn-wrapper mt-0">
													<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit" onClick={submit}>Send Message</button>
												</div>
												<p className="form-messege mb-0 mt-20" />
											</form>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		)
	}

	const mapStateToProps = state => ({
		contactDetailsRedux: state.contact,
		// loginError: state.login.loginError,
		// authenticating: state.login.authenticating,
	  })

	  const mapDispatchToProps = dispatch => ({
	  });

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)