import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { TypeAnimation } from 'react-type-animation';

class CounterV1 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__counterup-area section-bg-1 pt-10 pb-40 position-relative">
			{/* <div className="ocean">
				<div className="wave"></div>
				<div className="wave"></div>
			</div> */}
			<div className="container ">
				<div className="row mb-3">
					<div className="col-md-12 text-center">
						<TypeAnimation
							sequence={[
								// Same substring at the start will only be typed out once, initially
								'Inspired by you.',
								1000, // wait 1s before replacing "Mice" with "Hamsters"
								'Delivered by us.',
								1000
							]}
							wrapper="span"
							speed={20}
							style={{ fontSize: '2em', display: 'inline-block' }}
							repeat={Infinity}
						/>
						{/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> */}
					</div>
				</div>
				<div className="row">
					{/* <div className="col-md-3 col-sm-6 align-self-center col-xs-6">
						<div className="ltn__counterup-item text-color-white---">
							<div className="counter-icon">
								<div className="counter-icon-img">
									<img src={publicUrl + "assets/img/llabdhi_img/calendar-white.svg"} />
								</div>
								 <i className="flaticon-select" /> 
							</div>
							<h1><span className="counter">20</span><span className="counterUp-icon">+</span> </h1>
							<h6>Years</h6>
						</div>
					</div> */}
						<div className="col-md-4 col-sm-6 align-self-center col-xs-6"></div>
					<div className="col-md-4 col-sm-6 align-self-center col-xs-6">
						<div className="ltn__counterup-item text-color-white---">
							<div className="counter-icon">
								<div className="counter-icon-img">
									<img src={publicUrl + "assets/img/llabdhi_img/building-white.svg"} />
								</div>
							</div>
							<h1><span className="counter">110</span><span className="counterUp-letter"></span><span className="counterUp-icon">+</span> </h1>
							<h6>Colour Design Varients</h6>
						</div>
					</div>
					<div className="col-md-4 col-sm-6 align-self-center col-xs-6"></div>

					{/* <div className="col-md-3 col-sm-6 align-self-center col-xs-6">
						<div className="ltn__counterup-item text-color-white---">
							<div className="counter-icon">
								<div className="counter-icon-img">
									<img src={publicUrl + "assets/img/llabdhi_img/groupwithbag-white.svg"} />
								</div>
							</div>
							<h1><span className="counter">1000</span><span className="counterUp-icon">+</span> </h1>
							<h6>Projects Completed</h6>
						</div>
					</div> */}
					{/* <div className="col-md-3 col-sm-6 align-self-center col-xs-6">
						<div className="ltn__counterup-item text-color-white---">
							<div className="counter-icon">
								<div className="counter-icon-img">
									<img src={publicUrl + "assets/img/llabdhi_img/projector-white.svg"} />
								</div>
							</div>
							<h1><span className="counter">12</span><span className="counterUp-icon">+</span> </h1>
							<h6>Awards</h6>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	}
}

export default CounterV1