import React from 'react';
// import ColorSwatches from '../section-components/color-swatch';
import { ToastContainer } from 'react-toastify';

const Toaster = ({message}) => {
		return 	<div>
			<ToastContainer 
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</div>
	}


export default Toaster