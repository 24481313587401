import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Sidebar from './shop-sidebar';
import config from '../../config/config';
import Select from "react-select";

const ShopGridV1 = () => {

	const getProductsData =  async () => {
		const headersData = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
			// Authorization: 'Bearer ' + value
		}
	
		try {
			const res = await fetch(`${config.baseUrl}api/fetch_product`, {
				method: 'GET',
				headers: headersData,
				// body: JSON.stringify(data)
			});
				
				const json = await res.json();
			if (json !== undefined) {
				// console.log('fetch_product res ==', res);
				console.log('fetch_product ==', json?.data);
				setProducts([]);
				setFilteredProductsData([]);
				setProducts(json.data);
				setFilteredProductsData(json.data);
			}
		} catch (e) {
			console.log('fetch_product Error ==', e);
		}  finally {
			// setLoaderStatus(false);
		}

		try {
			const res = await fetch(`${config.baseUrl}api/fetch_product_categories`, {
				method: 'GET',
				headers: headersData,
				// body: JSON.stringify(data)
			});
				
				const json = await res.json();
			if (json !== undefined) {
				// console.log('fetch_product res ==', res);
				console.log('fetch_product_categories ==', json?.data);
				setProductCategories([]);
				setProductCategories(json.data);
			}
		} catch (e) {
			console.log('fetch_product_categories Error ==', e);
		}  finally {
			// setLoaderStatus(false);
		}
	}

	useEffect(() => {
		if(products?.length == 0) {
			getProductsData();
		}
	}, [])

	const [products, setProducts] = useState([]);
	const [productCategories, setProductCategories] = useState([]);

	const [searchQuery, setSearchQuery] = useState("");
    const [filteredProductsData, setFilteredProductsData] = useState([]);
    const [sortingBy, setSortingBy] = useState("none");
    const [selectedCat, setSelectedCat] = useState([]);
	// const [Type, setServiceType] = useState(0);

	  function renderProducts() {
		return (
		  <>
			{filteredProductsData?.map((item, index) => {
			var slug;
			{item?.id == 5 ? slug = "2bba-mill-finish" :
				item?.id == 6 ? slug = "hairline-finish-stainless-steel-sheets" :
				item?.id == 7 ? slug = "super-mirror-sheet" :
				item?.id == 8 ? slug = "stainless-steel-strips" :
				slug = "stainless-steel-no4-brushed-finish"
			}
			 return (
				<div className="col-lg-4 col-sm-6 col-12" key={item?.id}>
					<div className="ltn__product-item ltn__product-item-4 text-center---">
						<div className="product-img go-top">
							<Link to={{ pathname: `/product-details/${slug}`, state: slug }}><img src={item?.feature_image ? config.assetUrl + "uploads/product_image/" + item?.feature_image : publicUrl + "assets/img/llabdhi_img/product_img.jpg" } alt="#" /></Link>
							<div className="product-badge">
								<ul>
									<li className="sale-badge bg-primary">{item?.productcategories[0]?.name}</li>
								</ul>
							</div>
						</div>
						<div className="product-info">
							<div className="d-flex product-title-container">
								<h2 className="product-title go-top"><Link to={{ pathname: `/product-details/${slug}`, state: slug }}>{item?.title}</Link></h2>
							</div>
							<div className="product-short-desc">
								{item?.shortdescription ? parse(item?.shortdescription) : null}
							</div>
						</div>
						<div className="bottom-row">
							<Link className="btn btn-effect-3 padding-small" to="#" data-bs-toggle="modal" data-bs-target="#inquiry-modal">Make An Enquiry</Link>
						</div>
					</div>
				</div>
			 )
			})}
		  </>
		);
	  }

	let publicUrl = process.env.PUBLIC_URL+'/';

    return <div>
				<div className="ltn__product-area ltn__product-gutter">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 order-lg-2 mb-100">
								<div className="tab-pane fade active show" id="liton_product_list">
									<div className="ltn__product-tab-content-inner ltn__product-list-view">
										<div className="row">
											{filteredProductsData?.map((item, index) => {
												var slug;
												{item?.id == 5 ? slug = "2bba-mill-finish" :
													item?.id == 6 ? slug = "hairline-finish-stainless-steel-sheets" :
													item?.id == 7 ? slug = "super-mirror-sheet" :
													item?.id == 8 ? slug = "stainless-steel-strips" :
													slug = "stainless-steel-no4-brushed-finish"
												}
												return (
													<div className="col-lg-12" key={index}>
														<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
															<div className="product-img go-top">
																<Link to={{ pathname: `/product-details/${slug}`, state: slug }}><img src={item?.feature_image ? config.assetUrl + "uploads/product_image/" + item?.feature_image : publicUrl + "assets/img/llabdhi_img/product_img.jpg" } alt="#" /></Link>
															</div>
															<div className="product-info">
																<div className="product-badge">
																	<ul>
																		<li className="category-badge">{item?.productcategories[0]?.name}</li>
																	</ul>
																</div>
																<div className="d-flex justify-content-between align-items-center justify-xs-content-center">
																	<h2 className="product-title go-top"><Link to={{ pathname: `/product-details/${slug}`, state: slug }}>{item?.title}</Link></h2>
																</div>
																<div className="mt-3">
																	{item?.shortdescription && parse(item?.shortdescription)}
																</div>
															</div>
															<div className="product-info-bottom">
																<div className="d-flex justify-xs-between w-xs-100 mt-xs-25">
																	<div className="d-flex flex-xs-column">
																		Material Grade: 
																		<div className="material-grade-tag">
																			Mild Steel
																		</div>
																	</div>
																	<div className="d-flex ms-3 flex-xs-column">
																		Industry:
																		<div className="industry-grade-tag">
																			Construction
																		</div>
																	</div>
																</div>
																<Link className="product-btn" to={{ pathname: `/product-details/${slug}`, state: slug }}>Know More</Link>
															</div>
														</div>
													</div>
												)
											})}
											
									
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>

        }

export default ShopGridV1