import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import config from '../../config/config';

const TeamV2 = () => {

	const [team, setTeam] = useState([]);
	let publicUrl = process.env.PUBLIC_URL + '/'
	let imagealt = 'image'

	// const [team, setTeam] = useState([
	// 	{id:1 , name:"John Doe", designation:'Manager', image:`${publicUrl}5-2.jpg`},
	// 	{id:2 , name:"John Doe", designation:'Manager', image:`${publicUrl}5-2.jpg`},
	// 	{id:3 , name:"John Doe", designation:'Manager', image:`${publicUrl}5-2.jpg`},
	// 	{id:4 , name:"John Doe", designation:'Manager', image:`${publicUrl}5-2.jpg`},
	// 	{id:5 , name:"John Doe", designation:'Manager', image:`${publicUrl}5-2.jpg`},
	// 	{id:6 , name:"John Doe", designation:'Manager', image:`${publicUrl}5-2.jpg`},
	// 	{id:7 , name:"John Doe", designation:'Manager', image:`${publicUrl}5-2.jpg`},
	// ]);

	const getTeam =  async () => {
		const headersData = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
			// Authorization: 'Bearer ' + value
		}
	
		try {
			const res = await fetch(`${config.baseUrl}api/fetch_team_member`, {
				method: 'GET',
				headers: headersData,
				// body: JSON.stringify(data)
			});
				
				const json = await res.json();
			if (json !== undefined) {
				// console.log('fetch_team_member res ==', res);
				console.log('fetch_team_member ==', json?.data);
				setTeam([]);
				setTeam(json.data.slice(0, 6));
			}
		} catch (e) {
			console.log('fetch_team_member Error ==', e);
		}  finally {
			// setLoaderStatus(false);
		}
	}

	useEffect(() => {
		getTeam();
	}, [])


	return <div className="ltn__team-area pt-110--- pb-90">
		<div className="container">
			<div className="row justify-content-center go-top">
				{team?.map((item, index) => {
					return (
						<div className="col-lg-3 col-sm-6">
							<div className="ltn__team-item ltn__team-item-3---">
								<div className="team-img">
									<img src={config.assetUrl + "uploads/team/" + item?.image} alt="Image" />
									{/* <img src={publicUrl + "assets/img/llabdhi_img/" + item?.image} alt="Image" /> */}
								</div>
								<div className="team-info">
									<h4><Link to="/team-details">{item?.name}</Link></h4>
									<h6 className="ltn__secondary-color">{item?.designation}</h6>
								</div>
							</div>
						</div>
					)
				})}
				
				{/* <div className="col-lg-3 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
							<img src={publicUrl + "assets/img/llabdhi_img/testimonial-girl.jpg"} alt="Image" />
						</div>
						<div className="team-info">
							<h4><Link to="/team-details">Kelian Anderson</Link></h4>
							<h6 className="ltn__secondary-color">Selling Agents</h6>
							<div className="ltn__social-media">
								<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
							<img src={publicUrl + "assets/img/llabdhi_img/5-2.jpg"} alt="Image" />
						</div>
						<div className="team-info">
							<h4><Link to="/team-details">Miranda H. Halim</Link></h4>
							<h6 className="ltn__secondary-color">CEO</h6>
							<div className="ltn__social-media">
								<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
							<img src={publicUrl + "assets/img/llabdhi_img/person.jpg"} alt="Image" />
						</div>
						<div className="team-info">
							<h4><Link to="/team-details">Damble D. Browni.</Link></h4>
							<h6 className="ltn__secondary-color">CEO</h6>
							<div className="ltn__social-media">
								<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
							<img src={publicUrl + "assets/img/llabdhi_img/testimonial-girl.jpg"} alt="Image" />
						</div>
						<div className="team-info">
							<h4><Link to="/team-details">Aiden Benjamin</Link></h4>
							<h6 className="ltn__secondary-color">Manager</h6>
							<div className="ltn__social-media">
								<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
							<img src={publicUrl + "assets/img/llabdhi_img/5-2.jpg"} alt="Image" />
						</div>
						<div className="team-info">
							<h4><Link to="/team-details">James Carter</Link></h4>
							<h6 className="ltn__secondary-color">Selling Agents</h6>
							<div className="ltn__social-media">
								<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
							<img src={publicUrl + "assets/img/llabdhi_img/person.jpg"} alt="Image" />
						</div>
						<div className="team-info">
							<h4><Link to="/team-details">James Carter</Link></h4>
							<h6 className="ltn__secondary-color">Selling Agents</h6>
							<div className="ltn__social-media">
								<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
							<img src={publicUrl + "assets/img/llabdhi_img/testimonial-girl.jpg"} alt="Image" />
						</div>
						<div className="team-info">
							<h4><Link to="/team-details">James Carter</Link></h4>
							<h6 className="ltn__secondary-color">Selling Agents</h6>
							<div className="ltn__social-media">
								<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	</div>
}


export default TeamV2