import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ShopLeftSidebar from './shop-components/shop-left-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import PageHeaderAnimated from './global-components/page-header-animated';
import { Helmet } from 'react-helmet-async';

const ShopLeftSidebarPage = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'


    return <div>
        <Helmet>
            <title>Custom Stainless Steel Products | Stainless Steel Production | Llabdhi Industries</title>
            <meta name="description" content="Explore LLABDHI's range of stainless steel products, including custom creations and efficient production methods." />
        </Helmet>
        <Navbar />
        <div className={"ltn__breadcrumb-area text-left bg-overlay-black-60 bg-image "} style={{ paddingBottom: 0, marginBottom:0}} data-bs-bg={publicUrl+"assets/img/llabdhi_img/products_bg_image.png"}>
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="ltn__breadcrumb-inner text-center page-title-animated mb-80">
                        <h1 className="page-title">Crafting Steel</h1> 
                        <h1 className="page-title"> 
                        <span>Excellence</span>
                        </h1>

                        {/* <h1 className="page-title">You Ideate, We Create</h1> */}
                        {/* <p></p> */}
                    </div>
                </div>
                </div>
            </div>
        
        <ShopLeftSidebar />
        <CallToActionV1 />
        </div>
        <Footer />
   
    </div>
}

export default ShopLeftSidebarPage

