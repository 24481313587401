import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return (
        <div className="main-map-container mb-80">
            <div className="google-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60330.64828880017!2d72.8650499800912!3d19.078438333354832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1700593168839!5m2!1sen!2sin" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
            </div>
            <div className="address-container">
                <div className="single-address-container">
                    <h6>TALOJA FACTORY</h6>
                    <a>
                        Plot No A-85, 
                        Taloja Industrial Area,
                        MIDC Taloja, Village Pendhar,
                        Panvel, Raigad 410208
                        Maharashtra, India
                    </a>
                </div>
                <div className="single-address-container border-left">
                    <h6>PATALGANGA FACTORY</h6>
                    <a>
                        Plot No E-112,
                        Addl. Patalganga Industrial Area,
                        MIDC, Village Chawane,
                        Panvel, Raigad 410207,
                        Maharashtra, India
                    </a>
                </div>
            </div>
        </div>
    )}
}

export default Map