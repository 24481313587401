import { ADD_DETAIL, GET_DETAIL, DELETE_DETAIL, EDIT_DETAIL } from "../actionTypes/actionTypes";

const initialState = {
    contactDetailsRedux: [{
        entity_name: "Office Address",
        entity_data: "Brooklyn, New York, United States",
        entity_link: "https://maps.app.goo.gl/Sh6rAxfjDCXSaX289"
    },
    {
        entity_name: "Phone Number",
        entity_data: "+91 22 3504 1652",
        entity_link: "tel:912235041652"
    },
    {
        entity_name: "Email Address",
        entity_data: "info@llabdhi.com",
        entity_link: "mailto:info@llabdhi.com"
    }],
};

const contactsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DETAIL:
            return {
                ...state,
                data: action.data.data,
            };
        case GET_DETAIL:
            return {
                ...state,
                data: action.data.data,
            };
        case EDIT_DETAIL:
            return {
                ...state,
                data: action.data.data,
            };
        case DELETE_DETAIL:
            return {
                ...state,
                data: action.data.data,
            };
        default:
            return state;
    }
};

export default contactsReducer;
