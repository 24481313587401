import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeaderAbout from './global-components/page-header-about';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import AboutV1 from './section-components/about-v1';
import VisionSection from './section-components/vision-section';
import MissionSection from './section-components/mission-section';
import CoreValues from './section-components/core-values';
import CoreFeatures from './section-components/core-features';
import ProSlider from './section-components/product-slider-v1';
import TestimonialCustom from './section-components/testimonial-custom';
import BlogSliderCustom from './blog-components/blog-slider-custom';
import Sponsor from './section-components/sponsor-v1';
import config from '../config/config';
import ProductAdvantage from './section-components/product-advantage';
import { Helmet } from 'react-helmet-async';
import ExportCtaSection from './section-components/export-cta-section';

const About_v1 = () => {

    let publicUrl = process.env.PUBLIC_URL + '/'
    const [aboutContent, setAboutContent] = useState([]);

    const getAboutPageContent =  async () => {
        const headersData = {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      
        try {
          const res = await fetch(`${config.baseUrl}api/fetch_content_by_page_id/2`, {
            method: 'GET',
            headers: headersData,
          });
            
          const json = await res.json();
          if (json !== undefined) {
            console.log('fetch_pages_content ==', json?.data);
            setAboutContent(json?.data);
            // getContent(json?.data);
          }
        } catch (e) {
          console.log('fetch_pages_content Error ==', e);
        }  finally {
          // setTimeout(childFunc.current(), 500);
        //   makeSlider();
        }
    }

    useEffect(() => {
        getAboutPageContent();
    }, [])

    return <div>
        <Helmet>
            <title>Custom Stainless Steel | Polished Sheets & Coils Distributors</title>
            <meta name="description" content="Discover top-quality custom stainless steel products at LLabdhi. We offer polished stainless sheets and coils, serving as trusted distributors and suppliers." />
        </Helmet>
        <Navbar />
        <PageHeaderAbout headertitle="About Us" />
        <AboutV1 customClass={"custom-bg-1 include-bg-image"} />
        <VisionSection />
        <MissionSection />
        <Sponsor sectionClass={"section-bg-1"} />
        <ExportCtaSection />
        <CoreValues />
        <ProductAdvantage />
        {/* <ProSlider /> */}
        {/* <CoreFeatures /> */}
        {/* <BlogSliderCustom sectionClass={"section-bg-1"} /> */}
        {/* <TestimonialCustom sectionClass={"bg-white"} /> */}
        <CallToActionV1 />
        <Footer />
    </div>
}



export default About_v1

