import React from 'react';
import Navbar from './global-components/navbar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import Gallery from './section-components/gallery-v2';
import PageHeader from './global-components/page-header';
import { Helmet } from 'react-helmet-async';

const GalleryPage = () => {

    return (
    <div>
        {/* <Helmet>
            <title>Premium Stainless Steel Suppliers & Manufacturing | Llabdhi Industries</title>
            <meta name='description' content='Discover top-quality stainless steel sheets, plates, and custom parts at Llabdhi Industries. Trusted supplier, with expertise in steel manufacturing processes.' />
        </Helmet> */}
        <Navbar />
        <PageHeader headertitle="Gallery" />
        <Gallery />
        <CallToActionV1 />
        <Footer />
    </div>
)}

export default GalleryPage;
// export default Home_V1

