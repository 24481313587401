import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const ExportCtaSection = () => {

	let publicUrl = process.env.PUBLIC_URL+'/'

    return (
		<div className="latest-cta-section section-bg-1 py-150 overflow-hidden">
		
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-10 px-5 fade-in-up d-xs-flex flex-xs-column">
						<div className="section-title-area ltn__section-title-2--- mb-20 text-center">
							<div>
								{/* <h6 className="section-subtitle section-subtitle-2 secondary-color">What We Belive</h6> */}
								<h1 className="section-title">We bring a depth of experience to our <span>exporting</span> endeavors.</h1>
							</div>
							{/* <div>
								<p>
								At Llabdhi Group, our vision is to be the forefront innovator and reliable partner in the steel processing industry. We envision a future where precision and customization seamlessly converge to meet the unique demands of our valued customers. Specializing in the Processing & Distribution of stainless steel, our commitment extends beyond industry standards. We aspire to set new benchmarks by excelling in the delivery of exact slit, cut-to-size, and polished stainless steel sheets, coils, and blanks that consistently conform to the highest standards of processing tolerance. With a forward-thinking approach, we strive to be the go-to source for tailored steel solutions, providing excellence that stands the test of time.
								</p>
							</div> */}
						</div>
						{/* <div className="portfolio-left-container">
							<img src={publicUrl + "assets/img/llabdhi_img/vision_extra_image.jpg"} alt="About Us Image" />
						</div> */}
					</div>
					{/* <div className="col-lg-6 align-self-center">
						<div className="portfolio-section-wrap portfolio-section-left">
							<img src={publicUrl + "assets/img/llabdhi_img/vision_image.jpg"} alt="About Us Image" />
							
						</div>
					</div> */}
				</div>
			</div>
		</div>
	)}

export default ExportCtaSection;