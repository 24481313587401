import React from 'react';
import config from '../../config/config';

const ProductAdvantage = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'

    return (
		<div className="ltn__core-values-section make-in-india-section mb--100 pt-30 pb-120 go-top section-bg-1">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 align-self-center fade-in-up">
							<div className="about-us-info-wrap ml-lg-50">
								<div className="section-title-area ltn__section-title-2--- mb-20">
									<h6 className="section-subtitle section-subtitle-2 secondary-color">Llabdhi Advantages</h6>
									<h1 className="section-title">Product<span> Advantages</span></h1>
									{/* <p>Our core values are the foundation of everything we do. They are the guiding principles that shape our company's culture and drive our decision-making.</p> */}
								</div>

								<div className="box-cards-container">
									<ul class="pro-adv-section">
										<li>Made in India</li>
										<li>Upholding superior standards for the quality of stainless steel products</li>
										<li>Distinctive and elegant designs tailored to meet the needs of our customers</li>
										<li>Extensive expertise in global exports</li>
										<li>Our products are entirely recyclable and can be reused without any loss in quality</li>
									</ul>
								</div>

								{/* <div className="about-us-info-wrap-inner about-us-info-devide---">
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
								</div> */}
								{/* <div className="btn-wrapper animated">
								<Link to="/about" className="theme-btn-1 btn btn-effect-1 text-uppercase">About Us</Link>
								</div> */}
							</div>
						</div>
						<div className="col-lg-5 align-self-center">
							<div className="about-us-img-wrap prod-adv-img">
								<img src={publicUrl+"assets/img/llabdhi_img/Make_In_India.png"} alt="llabdhi" />
							</div>
						</div>
					</div>
				</div>
			</div>	
	)
}

export default ProductAdvantage