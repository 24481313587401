import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import BannerV2 from '../section-components/banner-v2';
import config from '../../config/config';
import { WOW } from 'wowjs';

const ProductDetailsSection = ({id}) =>  {

	const [loader, setLoader] = useState(true);
	useEffect(
        () => {
			console.log("url changed")
			// if(products?.length == 0) {
				getProducts();
			// }
	}, [id]);
// }, [useLocation().pathname]);
    

		// const url = window.location.href;
		// const pathname = window.location.pathname;
		let publicUrl = process.env.PUBLIC_URL + '/'
		const $ = window.$;
		const [products, setProducts] = useState([]);
		const [productDetails, setProductDetails] = useState();

		const getProducts =  async () => {
			const headersData = {
				Accept: 'application/json',
				'Content-Type': 'application/json'
				// Authorization: 'Bearer ' + value
			}
		
			// try {
			// 	const res = await fetch(`${config.baseUrl}api/fetch_product`, {
			// 		method: 'GET',
			// 		headers: headersData,
			// 		// body: JSON.stringify(data)
			// 	});
					
			// 		const json = await res.json();
			// 	if (json !== undefined) {
			// 		// console.log('fetch_product res ==', res);
			// 		console.log('fetch_product ==', json?.data);
			// 		setProducts([]);
			// 		setProducts(json.data.slice(0, 6));
			// 	}
			// } catch (e) {
			// 	console.log('fetch_product Error ==', e);
			// }  finally {
			// 	// loadToCarousel()
			// 	// setLoaderStatus(false);
			// }

			try {
				const res = await fetch(`${config.baseUrl}api/fetch_product_by_id/${id}`, {
					method: 'GET',
					headers: headersData,
					// body: JSON.stringify(data)
				});
					
					const json = await res.json();
				if (json !== undefined) {
					// console.log('fetch_product res ==', res);
					console.log('fetch_product_by_id ==', json?.data);
					// setProductDetails();
					setProductDetails(json.data);
				setLoader(false)
			}
			} catch (e) {
				console.log('fetch_product_by_id Error ==', e);
			}  finally {
				loadToCarousel2()
				// setLoaderStatus(false);
				
					
			
			}
		}

		const loadToCarousel2 = () => {
			
			// setTimeout(() => {
		// if(productDetails?.keyfeatures ) {
			$('.ltn__slider-11-active').not('.slick-initialized').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
				var i = (currentSlide ? currentSlide : 0) + 1;
				$('.ltn__slider-11-pagination-count .count').text('0'+i);
				$('.ltn__slider-11-pagination-count .total').text('0' + slick.slideCount);

				$('.ltn__slider-11-slide-item-count .count').text('0'+i);
				$('.ltn__slider-11-slide-item-count .total').text('/0' + slick.slideCount);
				new WOW({
					live: false
				}).init();
			}).slick({
				dots: false, /* slider left or right side pagination count with line */
				arrows: false, /* slider arrow  */
				appendDots: '.ltn__slider-11-pagination-count',
				infinite: true,
				autoplay: false,
				autoplaySpeed: 10000,
				speed: 500,
				asNavFor: '.ltn__slider-11-img-slide-arrow-active',
				slidesToShow: 1,
				slidesToScroll: 1,
				prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
				nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
				responsive: [
					{
						breakpoint: 1600,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: false
						}
					},
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: false
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: false
						}
					},
					{
						breakpoint: 575,
						settings: {
							arrows: false,
							dots: false,
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});

			$('.ltn__slider-11-img-slide-arrow-active').not('.slick-initialized').slick({
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2,
				centerMode: false,
				autoplay: true,
				speed: 700,
				centerPadding: '0px',
				asNavFor: '.ltn__slider-11-active',
				dots: false, /* image slide dots */
				arrows: true, /* image slide arrow */
				centerMode: true,
				focusOnSelect: true,
				prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
				nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
				responsive: [
					{
						breakpoint: 1600,
						settings: {
							arrows: false,
							dots: false
						}
					},
					{
						breakpoint: 1200,
						settings: {
							arrows: true,
							dots: false
						}
					},
					{
						breakpoint: 768,
						settings: {
							arrows: true,
							dots: false
						}
					},
					{
						breakpoint: 575,
						settings: {
							arrows: true,
							dots: false,
						}
					}
				]
			});
		}
		// useEffect(() => {
				
			
		// 	// }
		// 	// }, 9000);
		// }, [productDetails?.keyfeatures])

		// useEffect(() => {
		// 	// console.log("id ==", id, ", url ==", url, ", pathname ==", pathname)
		// 	if(products?.length == 0) {
		// 		getProducts();
		// 	}
		// }, [])

		// const [products, setProducts] = useState([
		// 	{
		// 		id: 1,
		// 		title: "Lorem Ipsum",
		// 		feature_image: "product_img.jpg",
		// 		type: "PVD Machine",
		// 		useFor: "Manufacturing",
		// 		shortdescription: "<p>werewr</p>",
		// 		keyfeatures: "[{\"keyfeatures\":\"waeaw\",\"keyimage\":\"1699982978.png\",\"id\":0}]",
		// 		productcategories: [
		// 			{
		// 				id: 1,
		// 				name: "Manufacturing",
		// 				parent_id: null,
		// 				created_at: "2023-11-13T18:33:14.000000Z",
		// 				updated_at: "2023-11-14T09:00:18.000000Z",
		// 				pivot: {
		// 					products_id: 1,
		// 					productcategory_id: 1
		// 				}
		// 			}
		// 		]
		// 	},
		// 	{
		// 		id: 2,
		// 		title: "Lorem Ipsum",
		// 		feature_image: "product_img.jpg",
		// 		type: "Coil",
		// 		useFor: "Manufacturing",
		// 		shortdescription: "<p>werewr</p>",
		// 		keyfeatures: "[{\"keyfeatures\":\"waeaw\",\"keyimage\":\"1699982978.png\",\"id\":0}]",
		// 		productcategories: [
		// 			{
		// 				id: 1,
		// 				name: "2Manufacturing",
		// 				parent_id: null,
		// 				created_at: "2023-11-13T18:33:14.000000Z",
		// 				updated_at: "2023-11-14T09:00:18.000000Z",
		// 				pivot: {
		// 					products_id: 1,
		// 					productcategory_id: 1
		// 				}
		// 			}
		// 		]
		// 	},
		// 	{
		// 		id: 3,
		// 		title: "Lorem Ipsum",
		// 		feature_image: "product_img.jpg",
		// 		type: "Wide Cut",
		// 		useFor: "Manufacturing",
		// 		shortdescription: "<p>werewr</p>",
		// 		keyfeatures: "[{\"keyfeatures\":\"waeaw\",\"keyimage\":\"1699982978.png\",\"id\":0}]",
		// 		productcategories: [
		// 			{
		// 				id: 1,
		// 				name: "Manufacturing",
		// 				parent_id: null,
		// 				created_at: "2023-11-13T18:33:14.000000Z",
		// 				updated_at: "2023-11-14T09:00:18.000000Z",
		// 				pivot: {
		// 					products_id: 1,
		// 					productcategory_id: 1
		// 				}
		// 			}
		// 		]
		// 	},
		// 	{
		// 		id: 4,
		// 		title: "Lorem Ipsum",
		// 		feature_image: "product_img.jpg",
		// 		type: "Hairline Finish",
		// 		useFor: "Manufacturing",
		// 		shortdescription: "<p>werewr</p>",
		// 		keyfeatures: "[{\"keyfeatures\":\"waeaw\",\"keyimage\":\"1699982978.png\",\"id\":0}]",
		// 		productcategories: [
		// 			{
		// 				id: 1,
		// 				name: "Manufacturing",
		// 				parent_id: null,
		// 				created_at: "2023-11-13T18:33:14.000000Z",
		// 				updated_at: "2023-11-14T09:00:18.000000Z",
		// 				pivot: {
		// 					products_id: 1,
		// 					productcategory_id: 1
		// 				}
		// 			}
		// 		]
		// 	},
		// 	{
		// 		id: 5,
		// 		title: "Lorem Ipsum",
		// 		feature_image: "product_img.jpg",
		// 		type: "Wide Cut",
		// 		useFor: "Manufacturing",
		// 		shortdescription: "<p>werewr</p>",
		// 		keyfeatures: "[{\"keyfeatures\":\"waeaw\",\"keyimage\":\"1699982978.png\",\"id\":0}]",
		// 		productcategories: [
		// 			{
		// 				id: 1,
		// 				name: "2Manufacturing",
		// 				parent_id: null,
		// 				created_at: "2023-11-13T18:33:14.000000Z",
		// 				updated_at: "2023-11-14T09:00:18.000000Z",
		// 				pivot: {
		// 					products_id: 1,
		// 					productcategory_id: 1
		// 				}
		// 			}
		// 		]
		// 	},
		// 	{
		// 		id: 6,
		// 		title: "Lorem Ipsum",
		// 		feature_image: "product_img.jpg",
		// 		type: "Hairline Finish",
		// 		useFor: "Manufacturing",
		// 		shortdescription: "<p>werewr</p>",
		// 		keyfeatures: "[{\"keyfeatures\":\"waeaw\",\"keyimage\":\"1699982978.png\",\"id\":0}]",
		// 		productcategories: [
		// 			{
		// 				id: 1,
		// 				name: "Manufacturing",
		// 				parent_id: null,
		// 				created_at: "2023-11-13T18:33:14.000000Z",
		// 				updated_at: "2023-11-14T09:00:18.000000Z",
		// 				pivot: {
		// 					products_id: 1,
		// 					productcategory_id: 1
		// 				}
		// 			}
		// 		]
		// 	}
		// ]);

		// function loadToCarousel() {
		// 	// $('.ltn__product-slider-item-four-active-full-width').slick('unslick');
		// 	$('.ltn__product-slider-item-four-active-full-width').not('.slick-initialized').slick({
		// 		arrows: true,
		// 		dots: true,
		// 		infinite: true,
		// 		autoplay: true,
		// 		autoplaySpeed: 1000,
		// 		pauseOnHover: false,
		// 		speed: 300,
		// 		slidesToShow: 4,
		// 		slidesToScroll: 1,
		// 		prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
		// 		nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
		// 		responsive: [
		// 			{
		// 				breakpoint: 1800,
		// 				settings: {
		// 					slidesToShow: 4,
		// 					slidesToScroll: 1
		// 				}
		// 			},
		// 			{
		// 				breakpoint: 1600,
		// 				settings: {
		// 					slidesToShow: 4,
		// 					slidesToScroll: 1
		// 				}
		// 			},
		// 			{
		// 				breakpoint: 1400,
		// 				settings: {
		// 					slidesToShow: 3,
		// 					slidesToScroll: 1
		// 				}
		// 			},
		// 			{
		// 				breakpoint: 1200,
		// 				settings: {
		// 					slidesToShow: 2,
		// 					slidesToScroll: 1
		// 				}
		// 			},
		// 			{
		// 				breakpoint: 992,
		// 				settings: {
		// 					arrows: false,
		// 					dots: true,
		// 					slidesToShow: 2,
		// 					slidesToScroll: 1
		// 				}
		// 			},
		// 			{
		// 				breakpoint: 768,
		// 				settings: {
		// 					arrows: false,
		// 					dots: true,
		// 					slidesToShow: 2,
		// 					slidesToScroll: 1
		// 				}
		// 			},
		// 			{
		// 				breakpoint: 575,
		// 				settings: {
		// 					arrows: false,
		// 					dots: true,
		// 					slidesToShow: 1,
		// 					slidesToScroll: 1
		// 				}
		// 			}
		// 		]
		// 	});
		// }

		function extractContent(s) {
			var span = document.createElement('span');
			span.innerHTML = s;
			return span.textContent || span.innerText;
		  };

		return <div className="ltn__shop-details-area pb-10 product-detail-page">
			{!loader ?

				<>
					<div className="container">
						<div className="row align-items-center mt-80 mb-60">
							{/* Image Area */}
							<div className="col-lg-6 col-md-12 product-img">
								<img src={productDetails?.feature_image ? config.assetUrl + "uploads/product_image/" + productDetails?.feature_image : publicUrl + "assets/img/llabdhi_img/product_img.jpg" } width={'100%'} />
								{/* <img src={publicUrl + "assets/img/llabdhi_img/steel beams possible baner.png"} width={'100%'} /> */}
							</div>
							<div className="col-lg-6 col-md-12">
								<div className="ltn__shop-details-inner ltn__page-details-inner">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-category mb-0">
												<Link to="#">{productDetails?.productcategories[0]?.name}</Link>
											</li>
											{/* <li className="ltn__blog-category">
									<Link className="bg-orange" to="#">For Rent</Link>
									</li>
									<li className="ltn__blog-date">
									<i className="far fa-calendar-alt" />May 19, 2021
									</li>
									<li>
									<Link to="#"><i className="far fa-comments" />35 Comments</Link>
									</li> */}
										</ul>
									</div>
									<h1 className="mt-0">{productDetails?.title}</h1>
									{/* <label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> Belmont Gardens, Chicago</label> */}
									<h4 className="title-2 mt-0 ps-0 mb-2">Product Overview</h4>
									{productDetails?.shortdescription && parse(productDetails?.shortdescription)}
									{/* <p>Massa tempor nec feugiat nisl pretium. Egestas fringilla phasellus faucibus scelerisque eleifend donec
										Porta nibh venenatis cras sed felis eget velit aliquet. Neque volutpat ac tincidunt vitae semper
										quis lectus. Turpis in eu mi bibendum neque egestas congue quisque. Sed elementum tempus egestas
										sed sed risus pretium quam. Dignissim sodales ut eu sem. Nibh mauris cursus mattis molestee
										iaculis at erat pellentesque. Id interdum velit laoreet id donec ultrices tincidunt.</p>
									<p>To the left is the modern kitchen with central island, leading through to the unique breakfast family room which feature glass walls and doors out onto the garden and access to the separate utility room.</p> */}
								</div>
							</div>
						</div>
					</div>
					{/* <BannerV2 data={productDetails?.keyfeatures && JSON?.parse(productDetails?.keyfeatures)} /> */}

					<div className="ltn__slider-area product-key-feature ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1">
					<div className="ltn__slider-11-inner">
						<div className="ltn__slider-11-active">
						{/* slide-item */}
						{productDetails?.keyfeatures && JSON?.parse(productDetails?.keyfeatures)?.map((item, index) => {
							return (
								<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11" key={index}>
									{/* <div className="video-overlay"></div> */}
									<div className="ltn__slide-item-inner">
									<div className="container">
										<div className="row">
										<div className="col-lg-12 align-self-center">
											<div className="slide-item-info">
												<div className="slide-item-info-inner ltn__slide-animation">
													{/* <div className="slide-video mb-50 d-none">
													<a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
														<i className="fa fa-play" />
													</a>
													</div> */}
													{/* <h6 className="slide-sub-title white-color--- animated mb-0">Key Feature</h6> */}
													{/* <h1 className="slide-title animated mt-0">{item?.keyfeatures}</h1> */}
												</div>
											</div>
											<div className="slide-item-img">
												<img src={item?.keyimage ? config.assetUrl + "uploads/product_image/" + item?.keyimage : publicUrl + "assets/img/llabdhi_img/product_img.jpg"} alt="#" />
											</div>
										</div>
										</div>
									</div>
									</div>
								</div>
							)
						})}
						
						</div>
						{/* slider-4-pagination */}
						<div className="ltn__slider-11-pagination-count">
						<span className="count" />
						<span className="total" />
						</div>
						{/* slider-4-img-slide-arrow */}
						<div className="ltn__slider-11-img-slide-arrow">
						<div className="ltn__slider-11-img-slide-arrow-inner">
							<div className="ltn__slider-11-img-slide-arrow-active">
								{productDetails?.keyfeatures && JSON?.parse(productDetails?.keyfeatures)?.map((item, index) => {
								return (
									<div className="image-slide-item" key={index}>
										<img src={item?.keyimage ? config.assetUrl + "uploads/product_image/" + item?.keyimage : publicUrl + "assets/img/llabdhi_img/product_img.jpg"} alt="Flower Image" />
									</div>
									
								)})}
								{/* <div className="image-slide-item">
										<img src={publicUrl+"assets/img/llabdhi_img/steel beams possible baner.png"} alt="Flower Image" />
									</div>
									<div className="image-slide-item">
										<img src={publicUrl+"assets/img/llabdhi_img/steel beams possible baner.png"} alt="Flower Image" />
									</div>
									<div className="image-slide-item">
										<img src={publicUrl+"assets/img/llabdhi_img/steel beams possible baner.png"} alt="Flower Image" />
									</div> */}
							</div>
							{/* slider-4-slide-item-count */}
							<div className="ltn__slider-11-slide-item-count">
							<span className="count" />
							<span className="total" />
							</div>
						</div>
						</div>
					</div>
					</div>
					{productDetails?.resourcetitle &&
						<div className="container">
							<div className="row">
								<div className="col-lg-12 col-md-12">
									<div className="ltn__shop-details-inner ltn__page-details-inner mb-60 mt-80">
							
										<h4 className="title-2">Product Resources & Specifications</h4>
										<div className="property-detail-info-list section-bg-1 clearfix mb-60">
											<ul>
												{productDetails?.resourcetitle && JSON?.parse(productDetails?.resourcetitle).map((item, index) => {
													return (
														<li key={index}>
															<div className="heading-feature">{item?.title}:</div>
															<div className="desc-feature">{item?.description}</div>
														</li>
													)
												})}
												{/* {[1, 1, 1, 1].map((item, index) => {
													return (
														<li key={index}>
															<div className="heading-feature">Track Rail Section:</div>
															<div className="desc-feature">60E1, 60E2, 60E1A1 / ZU-1-60, IRS 52, UIC 54 sections as per IRS-T-12 and EN-13674</div>
														</li>
													)
												})} */}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
					{/* {false && 
						<div className="container mt-40">
						<h4 className="title-2">Related Products</h4>
							<div className="row">
								<div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1">

									{products?.map((item) => {
										// let imageCount = JSON.parse(item?.keyfeatures)?.length;
										// console.log("imageCount ==", imageCount);
										return (
											<div className="col-lg-12" key={item?.id}>
												<div className="ltn__product-item ltn__product-item-4 text-center---">
													<div className="product-img go-top">
														<Link to={{ pathname: `/product-details/${item?.id}`, state: item?.id }}><img src={item?.feature_image ? config.assetUrl + "uploads/product_image/" + item?.feature_image : publicUrl + "assets/img/llabdhi_img/product_img.jpg"} alt="#" /></Link>
														<div className="product-badge">
															<ul>
																<li className="sale-badge bg-primary">{item?.productcategories[0]?.name}</li>
															</ul>
														</div>
													
													</div>
													<div className="product-info">
														<div className="d-flex product-title-container">
															<h2 className="product-title go-top"><Link to={{ pathname: `/product-details/${item?.id}`, state: item?.id }}>{item?.title}</Link></h2>
														</div>
														<div className="product-short-desc">
															{item?.shortdescription ? extractContent(item?.shortdescription)?.substring(0, 100) + "..."  : null}
														</div>
													</div>
													<div className="bottom-row">
														<Link className="btn btn-effect-3" to="#" data-bs-toggle="modal" data-bs-target="#inquiry-modal">Make An Enquiry</Link>
													</div>
												</div>
											</div>
										)
									})}
						
								</div>
							</div>
						</div>
					} */}
				</>
			: null }
		</div>
		
	
	}


export default ProductDetailsSection