import React, { useEffect, useState } from 'react';
// import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';
// import Toaster from '../global-components/toaster';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import config from '../../config/config';

function ProductInquiryModal (props) {

	const [fullName, setFullName] = useState();
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState();
	const [company, setCompany] = useState();
	const [qty, setQty] = useState();
	// const [expDate, setExpDate] = useState();
	const [address, setAddress] = useState();
	const [city, setCity] = useState();
	const [state, setState] = useState();
	const [zipCode, setZipCode] = useState();
	const [requestedData, setRequestedData] = useState();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

		let publicUrl = process.env.PUBLIC_URL + '/'

		const [show, setShow] = useState(false); 
		const toggleModal = () => {
			setShow(!show);
		} 

		useEffect(() => {
			console.log('clicked');
			setShow(props.show);
		},[props])

		const validate = () => {
			return !(
				!fullName ||
				fullName?.trim().length === 0 ||
				!phone ||
				phone?.trim().length === 0 ||
				!email ||
				email?.trim().length === 0 ||
				// email?.trim().length < 6 ||
				// email?.indexOf("@") < 0 ||
				email?.indexOf(" ") >= 0 ||	
				// !company ||
				// company?.trim().length === 0 ||
				// !qty ||
				// qty?.trim().length === 0 ||
				// qty === 0 ||
				// !expDate ||
				// expDate?.trim().length === 0 ||
				!address ||
				address?.trim().length === 0
			);
		};

		const submit = (event) => {
			
			event.preventDefault();
		
			console.log(fullName, phone, email, company, address);
			console.log("testing");
			 if (!validate()) {
				if (!fullName || fullName?.trim().length === 0)  return toast("Please enter full name", {type: "error"});
				if (!email || email?.trim().length === 0 || email?.trim().length < 6 || email?.indexOf("@") < 0 || email?.indexOf(" ") >= 0)  return toast('Please enter valid email address', {type: "error"});
				if (!phone || phone?.trim().length === 0) return  toast('Please enter phone number', {type: "error"});
				// if (!company || company?.trim().length === 0) return toast('Please enter Company Name', {type: "error"});
				// if (!qty || qty?.trim().length === 0 || qty === 0) return toast('Please Enter Quantity', {type: "error"});
				// if (!expDate || expDate?.trim().length === 0) return toast('Please enter Expected Date', {type: "error"});
				if (!address || address?.trim().length === 0) return toast('Please enter Address', {type: "error"});
			}

			console.log("testing23");
			handleClose();
			

			// document.getElementById('corporate-inquiry-modal').modal('hide')
			const data = {
				name: fullName?.trim(),
				email: email?.trim(),
				phone: phone?.trim(),
				company_name: company?.trim(),
				address: address?.trim(),
				product_id: props.productId,
				quantity: 1,
			};
			// const data = new FormData();
			// data.append("fullName", fullName?.trim());
			// data.append("email", email?.trim());
			// data.append("phone", phone?.trim());
			// data.append("company", company?.trim());
			// data.append("address", address?.trim());
			// data.append("product_id", 5);
			// data.append("quantity", 1);

			return makeProductEnquiry(data);
			// return toast("Form submitted successfully!", {type: "success"});
		}

		// const makeProductEnquiry =  async (data) => {
			const makeProductEnquiry = async (data) => {
				// data.preventDefault();
				try {
				  let res = await fetch(`${config.baseUrl}api/add-product-enquiry`, {
					method: 'POST',
					headers: {
					  Accept: 'application/json',
					  'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				})
				let resJson = await res.json();
				console.log("resJson ===", resJson);
				if (res.status === 200 || res.status === 201) {
					toast("Form submitted successfully!", {type: "success"});
				} else {
				//   setMessage("Some error occured");
				}
			  } catch (err) {
				console.log(err);
			  }
			};
				
					
				

		return <><Modal className="ltn__modal-area ltn__quick-view-modal-area"  show={props.show} onHide={props.toggleShowModal}>
		{/* <div className="modal fade" id="corporate-inquiry-modal22" tabIndex={-1}>
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
							 <i class="fas fa-times"></i> 
						</button>
					</div> */}
		<div className="" id="inquiry-modal" tabIndex={-1}>
			{/* <div className="modal-dialog modal-lg" role="document"> */}
				<div className="modal-content">
					<div className="modal-header">
						<a className="close" onClick={()=>props.toggleShowModal()}>
							{/* <span aria-hidden="true">×</span> */}
							 <i className="fas fa-times"></i> 
						</a>
					</div>
					<Modal.Body className="modal-body">
						<div className="ltn__quick-view-modal-inner">
							<div className="modal-product-item">
								<div className="row">

									<form >
										<h6>Personal Information</h6>
										<div className="row">
											<div className="col-md-6">
												<div className="input-item input-item-name ltn__custom-icon">
													<input type="text" name="fullName" placeholder="Name" onChange={e => setFullName(e.target.value)} required />
												</div>
											</div>
											<div className="col-md-6">
												<div className="input-item input-item-email ltn__custom-icon">
													<input type="email" name="email" placeholder="Email Address" onChange={e => setEmail(e.target.value)} required />
												</div>
											</div>
											<div className="col-md-6">
												<div className="input-item input-item-phone ltn__custom-icon">
													<input type="text" name="phone" placeholder="Phone Number" onChange={e => setPhone(e.target.value)} required />
												</div>
											</div>
											<div className="col-md-6">
												<div className="input-item input-item-website ltn__custom-icon">
													<input type="text" name="company" placeholder="Company Name (optional)" onChange={e => setCompany(e.target.value)}  />
												</div>
											</div>
											{/* <div className="col-md-6">
												<div className="input-item input-item-website ltn__custom-icon">
													<input type="text" name="ltn__phone" placeholder="Company Address (optional)" />
												</div>
											</div> */}
										</div>
										<div className="row">
											<div className="col-md-12">
												<h6>Address</h6>
												<div className="input-item">
													<input type="text" name="address" placeholder="Address (optional)" onChange={e => setAddress(e.target.value)} />
												</div>
											</div>
										</div>
										<div className="d-flex justify-content-center">
											{/* <a href="#" className="btn submit-btn btn-effect-1 theme-btn-1 text-uppercase" onClick={submit}>Enquire Now</a> */}
											<button className="btn submit-btn btn-effect-1 text-uppercase" onClick={submit}>Enquire Now</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</Modal.Body>
				</div>
			{/* </div> */}
		</div>
	</Modal>
	</>
	}


export default ProductInquiryModal