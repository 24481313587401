import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import ProductInquiryModal from '../global-components/product-inquiry-modal';

const ProductSliderV1 = () => {
	// class ProductSliderV1 extends Component {
	// const [data, setData] = useState();
	// render() {
	const $ = window.$;
	const [products, setProducts] = useState([])

	const [show, setShow] = useState(false);
	// const toggleShowModal = () => setShow(p => !p);
	const toggleShowModal = () => {
		setShow(false);
	}
	const [productId, setProductId] = useState();

	const getProducts =  async () => {
		const headersData = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
			// Authorization: 'Bearer ' + value
		}
	
		try {
			const res = await fetch(`${config.baseUrl}api/fetch_product`, {
				method: 'GET',
				headers: headersData,
				// body: JSON.stringify(data)
			});
				
				const json = await res.json();
			if (json !== undefined) {
				// console.log('fetch_product res ==', res);
				console.log('fetch_product ==', json?.data);
			
				setProducts(json.data.slice(0, 8));
			}
		} catch (e) {
			console.log('fetch_product Error ==', e);
		}  finally {
			loadToCarousel()
			// setLoaderStatus(false);
		}
	}

	function openPopup(productId) {
		setProductId(productId);
		setShow(true);
		// <ProductInquiryModal show={show} toggleShowModal={toggleShowModal} productId={productId} />
		return;
	}

	useEffect(() => {
		if(products?.length === 0) {
			setProducts([]);
			getProducts();
		}
	}, [])

	function loadToCarousel() {
		$('.ltn__product-slider-item-four-active-full-width').not('.slick-initialized').slick({
			arrows: true,
			dots: true,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 1000,
			pauseOnHover: false,
			speed: 1000,
			slidesToShow: 4,
			slidesToScroll: 1,
			prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
			nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
			responsive: [
				{
					breakpoint: 1800,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1600,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						dots: true,
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						dots: true,
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 575,
					settings: {
						arrows: false,
						dots: true,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}

	let publicUrl = process.env.PUBLIC_URL + '/'

	function extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		return span.textContent || span.innerText;
	  };

	function productLoop () {
		return (
			<>
			{products?.map((item) => {
				// let imageCount = JSON.parse(item?.keyfeatures)?.length;
				// console.log("imageCount ==", imageCount,", Image: ", item);
				var slug;
				{item?.id == 5 ? slug = "2bba-mill-finish" :
					item?.id == 6 ? slug = "hairline-finish-stainless-steel-sheets" :
					item?.id == 7 ? slug = "super-mirror-sheet" :
					item?.id == 8 ? slug = "stainless-steel-strips" :
					slug = "stainless-steel-no4-brushed-finish"
				}
				return (
					<div className="col-lg-12" key={item?.id}>
						<div className="ltn__product-item ltn__product-item-4 text-center---">
							<div className="product-img go-top">
								<Link to={{ pathname: `/product-details/${slug}`, state: slug }}><img src={item?.feature_image ? config.assetUrl + "uploads/product_image/" + item?.feature_image : publicUrl + "assets/img/llabdhi_img/product_img.jpg" } alt={item?.title} /></Link>
								<div className="product-badge">
									<ul>
										<li className="sale-badge bg-primary">{item?.productcategories[0]?.name}</li>
									</ul>
								</div>
							</div>
							<div className="product-info">
								<div className="d-flex product-title-container">
									<h2 className="product-title go-top"><Link to={{ pathname: `/product-details/${slug}`, state: slug}}>{item?.title}</Link></h2>
								</div>
								<div className="product-short-desc">
									{item?.shortdescription ? extractContent(item?.shortdescription)?.substring(0, 100) + "..."  : null}
								</div>
							</div>
							<div className="bottom-row">
								<Link to="#" className="btn btn-effect-3" onClick={() => openPopup(item?.id)}>Make An Enquiry</Link>
							</div>
						</div>
					</div>
			)})}
			</>
			)
	}

	return <div>
		<ProductInquiryModal show={show} toggleShowModal={toggleShowModal} productId={productId} />
		<div className="ltn__product-slider-area ltn__product-gutter pt-50 pb-50 plr--7 section-bg-1">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 secondary-color">Products</h6>
							<h1 className="section-title">Our <span>Products</span></h1>
						</div>
					</div>
				</div>
				<div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1">
				{products && 
					productLoop()
				}
				</div>
			</div>
		</div>
	</div>
}

export default ProductSliderV1;