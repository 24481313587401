import React from 'react';

const ContentPage = () => {

	return <div className={"ltn__about-us-area pb-90 "}>
		<div className="container">
			<div className="row">
				<div className="col-lg-12 align-self-center fade-in-up">
					<div className="about-us-info-wrap term-of-use-section">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h2><strong>Terms and Conditions</strong></h2>

							<p>Welcome to Llabdhi Manufacturing LLP!</p>

							<p>These terms and conditions outline the rules and regulations for the use of Llabdhi Manufacturing LLP's Website, located at https://llabdhi.com/.</p>

							<p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Llabdhi Manufacturing LLP if you do not agree to take all of the terms and conditions stated on this page.</p>

							<p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of in. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

							<h3><strong>Cookies</strong></h3>

							<p>We employ the use of cookies. By accessing Llabdhi Manufacturing LLP, you agreed to use cookies in agreement with the Llabdhi Manufacturing LLP's Privacy Policy. </p>

							<p>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

							<h3><strong>License</strong></h3>

							<p>Unless otherwise stated, Llabdhi Manufacturing LLP and/or its licensors own the intellectual property rights for all material on Llabdhi Manufacturing LLP. All intellectual property rights are reserved. You may access this from Llabdhi Manufacturing LLP for your own personal use subjected to restrictions set in these terms and conditions.</p>

							<p>You must not:</p>
							<ul>
								<li>Republish material from Llabdhi Manufacturing LLP</li>
								<li>Sell, rent or sub-license material from Llabdhi Manufacturing LLP</li>
								<li>Reproduce, duplicate or copy material from Llabdhi Manufacturing LLP</li>
								<li>Redistribute content from Llabdhi Manufacturing LLP</li>
							</ul>

							<p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the <a href="https://www.termsandconditionsgenerator.com/">Free Terms and Conditions Generator</a>.</p>

							<p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Llabdhi Manufacturing LLP does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Llabdhi Manufacturing LLP,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Llabdhi Manufacturing LLP shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

							<p>Llabdhi Manufacturing LLP reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>

							<p>You warrant and represent that:</p>

							<ul>
								<li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
								<li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
								<li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
								<li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
							</ul>

							<p>You hereby grant Llabdhi Manufacturing LLP a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

							<h3><strong>Hyperlinking to our Content</strong></h3>

							<p>The following organizations may link to our Website without prior written approval:</p>

							<ul>
								<li>Government agencies;</li>
								<li>Search engines;</li>
								<li>News organizations;</li>
								<li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
								<li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
							</ul>

							<p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>

							<p>We may consider and approve other link requests from the following types of organizations:</p>

							<ul>
								<li>commonly-known consumer and/or business information sources;</li>
								<li>dot.com community sites;</li>
								<li>associations or other groups representing charities;</li>
								<li>online directory distributors;</li>
								<li>internet portals;</li>
								<li>accounting, law and consulting firms; and</li>
								<li>educational institutions and trade associations.</li>
							</ul>

							<p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Llabdhi Manufacturing LLP; and (d) the link is in the context of general resource information.</p>

							<p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</p>

							<p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Llabdhi Manufacturing LLP. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

							<p>Approved organizations may hyperlink to our Website as follows:</p>

							<ul>
								<li>By use of our corporate name; or</li>
								<li>By use of the uniform resource locator being linked to; or</li>
								<li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
							</ul>

							<p>No use of Llabdhi Manufacturing LLP's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

							<h3><strong>iFrames</strong></h3>

							<p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

							<h3><strong>Content Liability</strong></h3>

							<p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

							<h3><strong>Reservation of Rights</strong></h3>

							<p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

							<h3><strong>Removal of links from our website</strong></h3>

							<p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>

							<p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

							<h3><strong>Disclaimer</strong></h3>

							<p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

							<ul>
								<li>limit or exclude our or your liability for death or personal injury;</li>
								<li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
								<li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
								<li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
							</ul>

							<p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>

							<p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
							<p>	Contact Us
								If you have any questions about these Terms and Conditions, You can contact us:</p>

								<p>	<b>By email:</b> info@llabdhigroup.in</p>
							{/* <h6 className="section-subtitle section-subtitle-2 secondary-color">About Us</h6>
							<h1 className="section-title mb-5">We believe that the entryway is <span>the centerpiece of every home.</span></h1> */}
							{/* <p>
								Please read these terms and conditions carefully before using Our Service.

								Interpretation and Definitions
								Interpretation
								The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.

								Definitions
								For the purposes of these Terms and Conditions:

								Affiliate means an entity that controls, is controlled by or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.

								Country refers to: India

								Company (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Llabdhi.

								Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.

								Service refers to the Website.

								Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.

								Website refers to Llabdhi, accessible from https://llabdhi.com/

								You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.

								Acknowledgment
								These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.

								Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.

								By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.

								Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.

								Links to Other Websites
								Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.

								The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.

								We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.

								Termination
								We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.

								Upon termination, Your right to use the Service will cease immediately.

								Limitation of Liability
								Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven’t purchased anything through the Service.

								To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.

								Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party’s liability will be limited to the greatest extent permitted by law.

								“AS IS” and “AS AVAILABLE” Disclaimer
								The Service is provided to You “AS IS” and “AS AVAILABLE” and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.

								Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.

								Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.

								Governing Law
								The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.

								Disputes Resolution
								If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.

								For European Union (EU) Users
								If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.

								United States Legal Compliance
								You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a “terrorist supporting” country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.

								Severability and Waiver
								Severability
								If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.

								Waiver
								Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party’s ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.

								Translation Interpretation
								These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.

								Contact Us
								If you have any questions about these Terms and Conditions, You can contact us:

								By email: info@llabdhigroup.in
							</p> */}
						</div>
						
						{/* <div className="ltn__callout bg-overlay-theme-05  mt-30">
							<p>"Enimad minim veniam quis nostrud exercitation <br />
								llamco laboris. Lorem ipsum dolor sit amet" </p>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default ContentPage