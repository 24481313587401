import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';
import config from '../../config/config';

const BlogList = () => {

	const [loader, setLoader] = useState(true);
	const [blogs, setBlogs] = useState([]);
	const $ = window.$;

	const getBlogs =  async () => {
		const headersData = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
			// Authorization: 'Bearer ' + value
		}
	
		try {
			const res = await fetch(`${config.baseUrl}api/fetch-blogs`, {
				method: 'GET',
				headers: headersData,
				// body: JSON.stringify(data)
			});
				
				const json = await res.json();
			if (json !== undefined) {
				// console.log('fetch_product res ==', res);
				console.log('fetch-blogs ==', json?.data);
				setBlogs(json?.data);
			}
		} catch (e) {
			console.log('fetch-blogs Error ==', e);
		}  finally {
			setLoader(false);
			// blogSlider();
			
		}
	}

	useEffect(() => {
		if(loader == true) {
			getBlogs();
		}
	}, [])

	let publicUrl = process.env.PUBLIC_URL + '/'
	return (
		<div className="ltn__blog-area mb-120">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__blog-area blog-custom ltn__blog-item-3-normal mb-100 go-top">
							<div className="row">
								{/* Blog Item */}
								{blogs.map((item, index) => {
									return(
										<div className="col-sm-6 col-lg-3 col-12" key={index}>
										<div className="ltn__blog-item ltn__blog-item-3">
											<div className="ltn__blog-img">
												<Link to={{ pathname: `/blog-details/${item?.slug}`, state: item?.slug }}><img src={config.assetUrl + "uploads/blog_images/"+item?.image} alt="#" /></Link>
											</div>
											<div className="ltn__blog-brief">
											<div className="ltn__blog-meta">
												<ul>
												<li className="ltn__blog-author go-top">
													<i className="far fa-user" />Author: Lladbhi
												</li>
												{/* <li className="ltn__blog-tags go-top">
													<Link to="/service"><i className="fas fa-tags" />Services</Link>
												</li> */}
												</ul>
											</div>
											<h3 className="ltn__blog-title"><Link to={{ pathname: `/blog-details/${item?.slug}`, state: item?.slug }}>{item?.title}</Link></h3>
											<Link className="ltn__blog-meta-btn" to={{ pathname: `/blog-details/${item?.slug}`, state: item?.slug }}>
													<div className="ltn__blog-btn">
														Read more <i className="fas fa-arrow-right"></i>
													</div>
												</Link>
											</div>
										</div>
										</div>
										// <div className="ltn__blog-item ltn__blog-item-5 go-top">
										// 	<div className="ltn__blog-img">
										// 		<Link to="/blog-details"><img src={config.assetUrl + "uploads/blogs/"+item?.thumbnail_image} alt="Image" /></Link>
										// 	</div>
										// 	<div className="ltn__blog-brief">
										// 		<div className="ltn__blog-meta">
										// 			<ul>
										// 				<li className="ltn__blog-category">
										// 					<Link to="/blog-grid">Business</Link>
										// 				</li>
										// 			</ul>
										// 		</div>
										// 		<h3 className="ltn__blog-title"><Link to="/blog-details">{item?.title}</Link></h3>
										// 		<div className="ltn__blog-meta">
										// 			<ul>
										// 				<li>
										// 					<Link to="/blog-details"><i className="far fa-eye" />232 Views</Link>
										// 				</li>
										// 				<li>
										// 					<Link to="/blog-details"><i className="far fa-comments" />35 Comments</Link>
										// 				</li>
										// 				<li className="ltn__blog-date">
										// 					<i className="far fa-calendar-alt" />June 22, 2020
										// 				</li>
										// 			</ul>
										// 		</div>
										// 		<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint</p>
										// 		<div className="ltn__blog-meta-btn">
										// 			<div className="ltn__blog-meta">
										// 				<ul>
										// 					<li className="ltn__blog-author">
										// 						<Link to="/blog-grid"><img src={publicUrl + "assets/img/blog/author.jpg"} alt="#" />By: Ethan</Link>
										// 					</li>
										// 				</ul>
										// 			</div>
										// 			<div className="ltn__blog-btn">
										// 				<Link to="/blog-details"><i className="fas fa-arrow-right" />Read more</Link>
										// 			</div>
										// 		</div>
										// 	</div>
										// </div>
								)})}
								{/*  */}
							</div>
						</div>
					</div>
					{/* <Sidebar /> */}
				</div>
			</div>
		</div>
	)
}

export default BlogList;
